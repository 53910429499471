import { queryOptions, useQuery } from '@tanstack/react-query'

import { emailToSendService } from '@/services/emailToSendService'

export const emailsToSendQuery = (id?: string) =>
  queryOptions({
    enabled: !!id,
    queryFn: () => emailToSendService.getEmailToSendById(id),
    queryKey: ['emailsToSendQuery', id],
  })

export const useEmailsToSend = (id?: string) => useQuery(emailsToSendQuery(id))
