import { queryOptions } from '@tanstack/react-query'

import { getEmailTemplate, getEmailTemplates, getEmailTemplatesByOrganization } from '@/api/email-templates'
import { getEmailTemplatesByOrganizationId } from '@/api/organizations'

/**
 * GET /email-templates
 */
export const emailTemplatesQuery = {
  queryFn: getEmailTemplates,
  queryKey: ['emailTemplates'],
}

/**
 * GET /email-templates/:id
 */
export const emailTemplateQuery = (id?: string) =>
  queryOptions({
    enabled: !!id,
    queryFn: () => getEmailTemplate(id),
    queryKey: ['emailTemplates', id],
  })

/**
 * GET /email-templates/organization/:organizationId
 */
export const emailTemplatesByOrganizationQuery = (organizationId: string) =>
  queryOptions({
    queryFn: () => getEmailTemplatesByOrganization(organizationId),
    queryKey: ['emailTemplatesByOrganization', organizationId],
  })

/**
 * GET /organizations/:organizationId/email-templates
 */
export const emailTemplatesByOrganizationIdQuery = (organizationId?: string) =>
  queryOptions({
    enabled: !!organizationId,
    queryFn: () => getEmailTemplatesByOrganizationId(organizationId),
    queryKey: ['emailTemplatesByOrganizationId', organizationId],
  })
