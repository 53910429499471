import { useQuery } from '@tanstack/react-query'

import { Skeleton } from '@/components/ui/skeleton'
import { applicationsApplicantInfoQuery } from '@/hooks/use-applications-queries'
import { ApplicantPersonalInformation } from '@/shared/presentation/applicant/ApplicantPersonalInformation'

interface ApplicantInformationsProps {
  applicationId: string
}

export function ApplicantInformations({ applicationId }: ApplicantInformationsProps) {
  const { data, isLoading } = useQuery(applicationsApplicantInfoQuery(applicationId))

  const { applicant } = data || {}

  if (isLoading || !applicant) return <ApplicantInformationSkeletons />

  return <ApplicantPersonalInformation applicant={applicant} />
}

function ApplicantInformationSkeletons() {
  return (
    <div className="flex gap-x-4 border-b px-8 py-4">
      <Skeleton className="size-16 rounded-full" />
      <div className="flex flex-col gap-y-1">
        <Skeleton className="h-10 w-56" />
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-4 w-32" />
      </div>
    </div>
  )
}
