import { z } from 'zod'

const conditionSchema = z.object({
  condition: z.string(),
  field: z.string(),
  value: z.string(),
})

export const putOrPostAutomationsEmailCandidateSchema = z.object({
  conditions: z.array(conditionSchema),
  delay: z.number().nonnegative(),
  emailTemplateId: z.string().min(1, 'Email template is required.'),
  from: z.string().min(1, 'Recruiter is required.'),
})
export type PutOrPostAutomationsEmailCandidateValues = z.infer<typeof putOrPostAutomationsEmailCandidateSchema>

export const automationsEmailCanditateValidations = {
  conditions: putOrPostAutomationsEmailCandidateSchema.shape.conditions,
  delay: putOrPostAutomationsEmailCandidateSchema.shape.delay,
  emailTemplateId: putOrPostAutomationsEmailCandidateSchema.shape.emailTemplateId,
  from: putOrPostAutomationsEmailCandidateSchema.shape.from,
}

export const automationStepEnum = z.enum([
  'comment',
  'create',
  'email-candidate',
  'email-recruiter',
  'schedule-interview',
  'show',
  'task-recruiter',
])

export type AutomationStep = z.infer<typeof automationStepEnum>

export const putOrPostAutomationsEmailRecruiterSchema = z.object({
  conditions: z.array(conditionSchema),
  delay: z.number().nonnegative(),
  emailTemplateId: z.string().min(1, 'Email template is required.'),
  from: z.string().min(1, 'Recruiter is required.'),
  to: z.string().array().nonempty('At least one recruiter is required'),
})

export type PutOrPostAutomationsEmailRecruiterValues = z.infer<typeof putOrPostAutomationsEmailRecruiterSchema>

export const automationsEmailRecruiterValidations = {
  conditions: putOrPostAutomationsEmailRecruiterSchema.shape.conditions,
  delay: putOrPostAutomationsEmailRecruiterSchema.shape.delay,
  emailTemplateId: putOrPostAutomationsEmailRecruiterSchema.shape.emailTemplateId,
  from: putOrPostAutomationsEmailRecruiterSchema.shape.from,
  to: putOrPostAutomationsEmailRecruiterSchema.shape.to,
}

export const putOrPostAutomationsTaskRecruiterSchema = z.object({
  conditions: z.array(conditionSchema),
  content: z.string().min(1, 'Content is required.'),
  dueDate: z.date().optional().nullable(),
  lead: z.string().min(1, 'Lead is required.'),
  performers: z.string().array().nonempty('At least one performer is required'),
  priority: z.string().min(1, 'Priority is required.'),
  status: z.string().min(1, 'Status is required.'),
})

export type PutOrPostAutomationsTaskRecruiterValues = z.infer<typeof putOrPostAutomationsTaskRecruiterSchema>
export const automationsTaskRecruiterValidations = {
  conditions: putOrPostAutomationsTaskRecruiterSchema.shape.conditions,
  content: putOrPostAutomationsTaskRecruiterSchema.shape.content,
  dueDate: putOrPostAutomationsTaskRecruiterSchema.shape.dueDate,
  lead: putOrPostAutomationsTaskRecruiterSchema.shape.lead,
  performers: putOrPostAutomationsTaskRecruiterSchema.shape.performers,
  priority: putOrPostAutomationsTaskRecruiterSchema.shape.priority,
  status: putOrPostAutomationsTaskRecruiterSchema.shape.status,
}

export const putOrPostAutomationsCommentSchema = z.object({
  comment: z.string().min(1, 'Comment is required.'),
  conditions: z.array(conditionSchema),
})

export type PutOrPostAutomationsCommentValues = z.infer<typeof putOrPostAutomationsCommentSchema>
export const automationsCommentValidations = {
  comment: putOrPostAutomationsCommentSchema.shape.comment,
  conditions: putOrPostAutomationsCommentSchema.shape.conditions,
}

export const putOrPostAutomationsScheduleInterviewSchema = z
  .object({
    conditions: z.array(conditionSchema),
    duration: z.number().min(1, {
      message: 'Duration is required.',
    }),
    location: z.string().optional().nullable(),
    participant: z.string().min(1, {
      message: 'Participant is required.',
    }),
    type: z.string().min(1, {
      message: 'Type is required.',
    }),
    videoService: z.string().optional().nullable(),
  })
  .refine((data) => !(data.type === 'PHYSICAL' && !data.location), {
    message: 'Location is required for physical interview',
    path: ['location'],
  })
  .refine((data) => !(data.type === 'VISIO' && !data.videoService), {
    message: 'Video service is required for visio interview.',
    path: ['videoService'],
  })

export type PutOrPostAutomationsScheduleInterviewValues = z.infer<typeof putOrPostAutomationsScheduleInterviewSchema>
export const automationsScheduleInterviewValidations = {
  conditions: putOrPostAutomationsScheduleInterviewSchema.innerType().innerType().shape.conditions,
  duration: putOrPostAutomationsScheduleInterviewSchema.innerType().innerType().shape.duration,
  location: putOrPostAutomationsScheduleInterviewSchema.innerType().innerType().shape.location,
  participant: putOrPostAutomationsScheduleInterviewSchema.innerType().innerType().shape.participant,
  type: putOrPostAutomationsScheduleInterviewSchema.innerType().innerType().shape.type,
  videoService: putOrPostAutomationsScheduleInterviewSchema.innerType().innerType().shape.videoService,
}
