/*
 ** Mutations
 */

import { useMutation } from '@tanstack/react-query'

import { cancelInterview, finishInterviews, rescheduleInterview, updateInterviewsNote } from '@/api/interviews'
import { applicationsLastActivitiesQuery } from '@/hooks/use-applications-queries'
import { UpdateInterviewsBody } from '@/types/interviews/note'

import { applicationsInterviewsQuery } from './use-applications-queries'

/**
 *  PUT /interviews/:interviewId/done
 */
export function useFinishInterview(applicationId: string) {
  return useMutation({
    meta: {
      invalidates: [
        applicationsInterviewsQuery(applicationId).queryKey,
        applicationsLastActivitiesQuery(applicationId).queryKey,
      ],
    },
    mutationFn: ({ interviewId }: { interviewId: string }) => finishInterviews(interviewId),
  })
}

/**
 * PUT /interviews/:interviewId/note
 */
export function useUpdateInterviewsNote(applicationId: string) {
  return useMutation({
    meta: {
      invalidates: [
        applicationsInterviewsQuery(applicationId).queryKey,
        applicationsLastActivitiesQuery(applicationId).queryKey,
      ],
    },
    mutationFn: ({ body, interviewId }: { interviewId: string; body: UpdateInterviewsBody }) =>
      updateInterviewsNote(interviewId, body),
  })
}

/**
 * PUT /interviews/:interviewId/cancel
 */
export function useCancelInterview(applicationId: string) {
  return useMutation({
    meta: {
      invalidates: [applicationsInterviewsQuery(applicationId).queryKey],
    },
    mutationFn: (interviewId: string) => cancelInterview(interviewId),
  })
}

/**
 * PUT /interviews/:interviewId/reschedule
 */
export function useRescheduleInterview(applicationId: string) {
  return useMutation({
    meta: {
      invalidates: [applicationsInterviewsQuery(applicationId).queryKey],
    },
    mutationFn: (interviewId: string) => rescheduleInterview(interviewId),
  })
}
