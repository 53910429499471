import { ApiGateway } from '@/modules/api/api.gateway'
import { JobSearch, JobStage } from '@/modules/job/domain/entities/job.entity'
import { IJobRepository } from '@/modules/job/domain/interfaces/job.interface'

export class JobRepository implements IJobRepository {
  constructor(private api: ApiGateway) {}

  async search(query: string): Promise<JobSearch[]> {
    return this.api.get<JobSearch[]>(`/jobs/search?q=${query}`)
  }

  async getStages(jobId: string): Promise<JobStage> {
    return this.api.get<JobStage>(`/jobs/${jobId}/stages`)
  }
}
