import { z } from 'zod'

import { convertMentionHtmlToVariables, removeHTMLTags } from '@/utils/string'

const refuseApplicationSchema = z
  .object({
    body: z.string().optional(),
    emailSendingDelay: z.number().int().optional(),
    emailTemplateId: z.string().optional(),
    hasEmail: z.boolean(),
    refusalReasonId: z.string(),
    subject: z
      .string()
      .optional()
      .transform((subject) => {
        if (!subject) return subject
        return removeHTMLTags(convertMentionHtmlToVariables(subject))
      })
      .pipe(z.string().max(255)),
  })
  .refine((data) => !(data.hasEmail && data.emailSendingDelay === null), {
    message: 'Delay is required.',
    path: ['emailSendingDelay'],
  })
  .refine((data) => !(data.hasEmail && !data.emailTemplateId), {
    message: 'Email template is required.',
    path: ['emailTemplateId'],
  })
  .refine((data) => !(data.hasEmail && !data.subject), {
    message: 'Subject is required.',
    path: ['subject'],
  })
  .refine((data) => !(data.hasEmail && !data.body), {
    message: 'Body is required.',
    path: ['body'],
  })

export type RefuseApplicationForm = z.infer<typeof refuseApplicationSchema>
export type RefuseApplication = Partial<Omit<z.infer<typeof refuseApplicationSchema>, 'hasEmail'>> & {
  customEmailToSendId?: string
}

export const updateStageSchema = z.object({
  fromStageId: z.string(),
  toStageId: z.string(),
})

export type UpdateStage = z.infer<typeof updateStageSchema>
export const applicationSchemas = {
  refuse: refuseApplicationSchema,
  updateStage: updateStageSchema,
}
