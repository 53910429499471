import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios'

import { refreshToken } from '@/api/auth'
import { User } from '@/providers/auth-provider'

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean
}

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

apiClient.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem('user')

    if (user) {
      const parsedUser = JSON.parse(user) as User
      if (parsedUser?.accessToken) {
        config.headers.Authorization = `Bearer ${parsedUser.accessToken}`
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

apiClient.interceptors.response.use(
  (response) => response,
  async (
    error: AxiosError<{
      message: string
      statusCode: number
      title?: string
    }>,
  ) => {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message)
    }

    const originalRequest = error.config as CustomAxiosRequestConfig

    if (error.response?.status === 401 && originalRequest && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        const user = localStorage.getItem('user')
        if (!user) {
          throw new Error('User not found')
        }

        const parsedUser = JSON.parse(user) as User
        const response = await refreshToken()
        localStorage.setItem('user', JSON.stringify({ ...parsedUser, accessToken: response.accessToken }))
        originalRequest.headers.Authorization = `Bearer ${response.accessToken}`

        localStorage.setItem('user', JSON.stringify({ ...parsedUser, accessToken: response.accessToken }))
        originalRequest.headers.Authorization = `Bearer ${response.accessToken}`

        return apiClient(originalRequest)
      } catch (refreshError) {
        localStorage.removeItem('user')

        const query = window.location.search
        const redirect = query ? query.substring(1) : ''
        const redirectToLogin = redirect ? `/auth/login?redirect=${redirect}` : '/auth/login'
        window.location.href = redirectToLogin

        return Promise.reject(refreshError)
      }
    }

    return Promise.reject(error)
  },
)

export default apiClient
