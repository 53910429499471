import { createContext, ReactNode, useContext, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { emailTemplateQuery } from '@/store/queries/useEmailTemplateQueries'

import { RefusalReasonForm } from './RefusalReasonForm'

interface RefusalReasonModalProps {
  applicationId: string
  fromStageId?: string
  toStageId?: string
  emailSendingDelay?: number
  emailTemplateId?: string
  refusalReasonId?: string
  customEmailToSendId?: string
  onCancel?: () => void
}

interface ModalContextType {
  openModal: (props: RefusalReasonModalProps) => void
  closeModal: () => void
  isOpen: boolean
}

interface ModalProviderProps {
  children: ReactNode
  jobId: string
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)

export const RefusalReasonModalProvider = ({ children, jobId }: ModalProviderProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalProps, setModalProps] = useState<RefusalReasonModalProps | null>(null)
  const queryClient = useQueryClient()

  const openModal = (props: RefusalReasonModalProps) => {
    setModalProps(props)
    setIsOpen(true)
  }

  const closeModal = () => {
    if (modalProps?.onCancel) {
      modalProps.onCancel()
      setIsOpen(false)
      return
    }

    setIsOpen(false)

    queryClient.removeQueries({ ...emailTemplateQuery(modalProps?.emailTemplateId) })

    setModalProps(null)
  }

  return (
    <ModalContext.Provider value={{ closeModal, isOpen, openModal }}>
      {children}
      <Dialog onOpenChange={closeModal} open={isOpen}>
        <DialogContent className="flex max-h-[calc(100vh-4rem)] min-h-[560px] w-full max-w-screen-lg flex-col overflow-auto">
          <DialogHeader>
            <DialogTitle>Choose the refusal reason for this application</DialogTitle>
          </DialogHeader>
          {modalProps && (
            <RefusalReasonForm
              applicationId={modalProps.applicationId}
              customEmailToSendId={modalProps.customEmailToSendId}
              emailSendingDelay={modalProps.emailSendingDelay}
              emailTemplateId={modalProps.emailTemplateId}
              fromStageId={modalProps.fromStageId}
              jobId={jobId}
              refusalReasonId={modalProps.refusalReasonId}
              toStageId={modalProps.toStageId}
            />
          )}
        </DialogContent>
      </Dialog>
    </ModalContext.Provider>
  )
}

export const useRefusalReasonModal = () => {
  const context = useContext(ModalContext)
  if (context === undefined) {
    throw new Error('useRefusalReasonModal must be used within a ModalProvider')
  }
  return context
}
