import { MentionOptions } from '@tiptap/extension-mention'
import { ReactRenderer } from '@tiptap/react'
import tippy, { GetReferenceClientRect, Instance, Props } from 'tippy.js'

import { MentionList } from './mention-list'

export function Suggestion({ char = '@', items }: { items: string[]; char?: string }): MentionOptions['suggestion'] {
  return {
    char,
    items: ({ query }: { query: string }) => {
      return items.filter((item) => item.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5)
    },
    render: () => {
      let component: ReactRenderer
      let popup: Instance<Props>[] | null = null

      return {
        onExit() {
          component.destroy()
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            component?.destroy()
            return true
          }

          // I know this is bad, but I don't know how to fix it
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          return (component?.ref as any)?.onKeyDown(props)
        },

        onStart: (props) => {
          component = new ReactRenderer(MentionList, {
            editor: props.editor,
            props,
          })

          popup = tippy('body', {
            appendTo: () => document.body,
            content: component.element,
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
            interactive: true,
            placement: 'bottom-start',
            showOnCreate: true,
            trigger: 'manual',
          })
        },

        onUpdate(props) {
          component?.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup?.[0]?.setProps({
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          })
        },
      }
    },
  }
}
