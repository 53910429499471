import { get } from '@/services/get'
import { post } from '@/services/post'
import { put } from '@/services/put'
import {
  ApplicationsDocument,
  UpdateApplicationsDocumentBody,
  UpdateApplicationsDocumentResponse,
} from '@/types/application/documents'

export function getApplicationsDocuments(applicationId: string) {
  return get<ApplicationsDocument[]>(`/applications/${applicationId}/documents`)
}

export function createApplicationsDocuments(applicationId: string, body: FormData) {
  return post<FormData, { url: string }>(`/applications/${applicationId}/documents`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function updateApplicationsDocuments(
  applicationId: string,
  documentId: string,
  body: UpdateApplicationsDocumentBody,
) {
  return put<UpdateApplicationsDocumentBody, UpdateApplicationsDocumentResponse>(
    `/applications/${applicationId}/documents/${documentId}`,
    body,
  )
}
