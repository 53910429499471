import { get } from '@/services/get'
import { put } from '@/services/put'
import { JobSettingsRecruiter } from '@/types/job'

/**
 * URL : /jobs/:jobId/recruiters
 */
export function getJobsRecruiters(jobId: string) {
  return get<JobSettingsRecruiter[]>(`/jobs/${jobId}/recruiters`)
}

/**
 * URL : /jobs/:jobId/recruiters
 */
export function updateJobsRecruiters(jobId: string, recruitersId: string[]) {
  return put<{ recruiters: string[] }, JobSettingsRecruiter[]>(`/jobs/${jobId}/recruiters`, {
    recruiters: recruitersId,
  })
}
