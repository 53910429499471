/* tslint:disable */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion'

import { cn } from '@/lib/utils'

interface MentionListProps extends SuggestionProps {}

export interface MentionListActions {
  onKeyDown: (props: SuggestionKeyDownProps) => void
}

const MentionList = forwardRef<MentionListActions, MentionListProps>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = (index: number) => {
    const item = props.items[index]

    if (item) {
      props.command({ id: item })
    }
  }

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return (
    <div className="flex flex-col gap-y-1 rounded bg-white p-2 shadow-lg">
      {props.items.length ? (
        props.items.map((item, index) => (
          <button
            className={cn('text-sm text-left p-1 rounded', index === selectedIndex && 'bg-muted')}
            key={index}
            onClick={() => selectItem(index)}
          >
            {item}
          </button>
        ))
      ) : (
        <div>No result</div>
      )}
    </div>
  )
})

export { MentionList }

MentionList.displayName = 'MentionList'
