import { VariantProps, cva } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const titleVariants = cva('font-medium', {
  defaultVariants: {
    align: 'left',
    level: 1,
    variant: 'default',
  },
  variants: {
    align: {
      center: 'text-center',
      left: 'text-left',
      right: 'text-right',
    },
    level: {
      1: 'text-5xl font-extrabold',
      2: 'text-4xl font-bold',
      3: 'text-3xl font-bold',
      4: 'text-2xl font-bold',
      5: 'text-xl font-bold',
      6: 'text-lg font-bold',
    },
    variant: {
      default: 'text-black',
      white: 'text-white',
    },
  },
})

interface TitleProps extends VariantProps<typeof titleVariants> {
  children: React.ReactNode
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  className?: string
}

const Title = ({ align, as = 'h1', children, className, level, variant }: TitleProps) => {
  const Comp = as

  return <Comp className={cn(titleVariants({ align, className, level, variant }))}>{children}</Comp>
}

Title.displayName = 'Title'

export { Title }
