import axios, {
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosInstance,
  AxiosRequestConfig as AxiosRequestConfigBase,
} from 'axios'

import { refreshToken } from '@/api/auth'
import { User } from '@/providers/auth-provider'

import { IApiGateway } from './api.interface'

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean
}

interface AxiosRequestConfig extends AxiosRequestConfigBase {
  customErrorHandling?: boolean
}

export class ApiGateway implements IApiGateway {
  private api: AxiosInstance

  constructor() {
    this.api = axios.create({
      baseURL: import.meta.env.VITE_API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    this.api.interceptors.request.use(
      (config) => {
        const user = localStorage.getItem('user')

        if (user) {
          const parsedUser = JSON.parse(user) as User
          if (parsedUser?.accessToken) {
            config.headers.Authorization = `Bearer ${parsedUser.accessToken}`
          }
        }

        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )

    this.api.interceptors.response.use(
      (response) => response,
      async (
        error: AxiosError<{
          message: string
          statusCode: number
          title?: string
        }>,
      ) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message)
        }

        const originalRequest = error.config as CustomAxiosRequestConfig

        if (error.response?.status === 401 && originalRequest && !originalRequest._retry) {
          originalRequest._retry = true

          try {
            const user = localStorage.getItem('user')
            if (!user) {
              throw new Error('User not found')
            }

            const parsedUser = JSON.parse(user) as User
            const response = await refreshToken()
            localStorage.setItem('user', JSON.stringify({ ...parsedUser, accessToken: response.accessToken }))
            originalRequest.headers.Authorization = `Bearer ${response.accessToken}`

            localStorage.setItem('user', JSON.stringify({ ...parsedUser, accessToken: response.accessToken }))
            originalRequest.headers.Authorization = `Bearer ${response.accessToken}`

            return this.api(originalRequest)
          } catch (refreshError) {
            localStorage.removeItem('user')

            const query = window.location.search
            const redirect = query ? query.substring(1) : ''
            const redirectToLogin = redirect ? `/auth/login?redirect=${redirect}` : '/auth/login'
            window.location.href = redirectToLogin

            return Promise.reject(refreshError)
          }
        }

        return Promise.reject(error)
      },
    )
  }

  async get<ResponseType>(url: string, config?: AxiosRequestConfig): Promise<ResponseType> {
    const response = await this.api.get<ResponseType>(url, config)
    return response.data
  }

  async post<ResponseType, RequestType = unknown>(
    url: string,
    data: RequestType,
    config?: AxiosRequestConfig,
  ): Promise<ResponseType> {
    const response = await this.api.post<ResponseType>(url, data, config)
    return response.data
  }

  async put<ResponseType, RequestType = unknown>(
    url: string,
    data: RequestType,
    config?: AxiosRequestConfig,
  ): Promise<ResponseType> {
    const response = await this.api.put<ResponseType>(url, data, config)
    return response.data
  }

  async delete<ResponseType>(url: string): Promise<ResponseType> {
    const response = await this.api.delete<ResponseType>(url)
    return response.data
  }
}
