import { Select } from '@/components/select'
import { useRefusalReasons } from '@/store/queries/useRefusalReasonQueries'

interface Props {
  onChange: (value: string) => void
  value?: string
}

export function RefusalReasonsSelect({ onChange, value }: Props) {
  const { data: refusalReasons, isLoading } = useRefusalReasons()

  return (
    <Select
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isLoading={isLoading}
      menuPosition="absolute"
      onChange={(option) => option && onChange(option.id)}
      options={refusalReasons}
      placeholder="Select a refusal reason..."
      value={refusalReasons?.find((reason) => reason.id === value)}
    />
  )
}
