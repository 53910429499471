import { JobBoardApplication } from '@/types/application/job-board'

import { InterviewInfo } from './components/interview-info'
import { LastComment } from './components/last-comment'
import { MatchScore } from './components/match-score'
import { UpdateAction } from './refusal-strategy/update-action'

interface CardFooterProps {
  application: JobBoardApplication
}

export function CardFooter({ application }: CardFooterProps) {
  return (
    <div className="flex items-center gap-x-2 border-t px-2 py-1">
      <LastComment value={application.lastComment} />
      <MatchScore value={application.matchScore} />
      {application.refusalReason && <UpdateAction application={application} />}
      {application.interviewInfo && !application.refusalReason && (
        <InterviewInfo
          date={(application.interviewInfo.createdAt || application.interviewInfo.scheduledAt)!}
          isPast={application.interviewInfo.isPast}
          status={application.interviewInfo.status}
        />
      )}
    </div>
  )
}
