import axios from 'axios'

export async function forgotPassword(email: string) {
  try {
    const response = await axios.post<undefined>(`${import.meta.env.VITE_API_URL}/auth/forgot-password`, {
      email,
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
