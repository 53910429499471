import { useState, useEffect, useCallback } from 'react'

interface CountdownOptions {
  targetDate: Date | string | number
  interval?: number
  onComplete?: () => void
  format?: 'full' | 'compact'
}

interface TimeLeft {
  days: number
  hours: number
  minutes: number
  seconds: number
  total: number
  completed: boolean
}

export function useCountdown({ format = 'full', interval = 1000, onComplete, targetDate }: CountdownOptions): TimeLeft {
  const calculateTimeLeft = useCallback((): TimeLeft => {
    try {
      const target = new Date(targetDate).getTime()
      const now = Date.now()
      const difference = target - now

      if (difference <= 0) {
        return {
          completed: true,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          total: 0,
        }
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((difference % (1000 * 60)) / 1000)

      return {
        completed: false,
        days,
        hours,
        minutes,
        seconds,
        total: difference,
      }
    } catch (error) {
      console.error('Erreur dans le calcul du countdown:', error)
      return {
        completed: true,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        total: 0,
      }
    }
  }, [targetDate])

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft())

  useEffect(() => {
    const initialTimeLeft = calculateTimeLeft()
    setTimeLeft(initialTimeLeft)

    if (initialTimeLeft.completed) {
      onComplete?.()
      return
    }

    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft()
      setTimeLeft(newTimeLeft)

      if (newTimeLeft.completed) {
        onComplete?.()
        clearInterval(timer)
      }
    }, interval)

    return () => clearInterval(timer)
  }, [targetDate, interval, onComplete, calculateTimeLeft])

  if (format === 'compact') {
    const { completed, days, hours, minutes, seconds } = timeLeft
    if (completed) return timeLeft

    if (days > 0) return { ...timeLeft, hours: hours + minutes / 60 }
    if (hours > 0) return { ...timeLeft, minutes: minutes + seconds / 60 }
    return timeLeft
  }

  return timeLeft
}
