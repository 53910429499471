import { createFileRoute, redirect } from '@tanstack/react-router'

import { Title } from '@/components/ui/title'
import { JOB_FILTERS_KEY } from '@/hooks/use-job-filters'
import { JobFilterValues } from '@/schemas/job'

export const Route = createFileRoute('/_authenticated/')({
  beforeLoad: () => {
    const jobFilters = localStorage.getItem(JOB_FILTERS_KEY)
    const jobFiltersParsed = jobFilters ? (JSON.parse(jobFilters) as Omit<JobFilterValues, 'page'>) : undefined
    throw redirect({
      search: jobFiltersParsed,
      to: '/jobs',
    })
  },
  component: Index,
})

function Index() {
  return (
    <div className="flex h-full items-center justify-center">
      <Title>Welcome to the ATS dashboard</Title>
    </div>
  )
}
