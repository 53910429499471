import { useQueryClient, QueryKey } from '@tanstack/react-query'

type DataWithId = { id: string }

export function useUpdateCache() {
  const queryClient = useQueryClient()

  return {
    addToList: <T>(queryKey: QueryKey, newData: T) => {
      queryClient.setQueryData<T[] | undefined>(queryKey, (oldData) => {
        if (!oldData) return [newData]
        return [...oldData, newData]
      })
    },
    invalidateQueries: async (queryKey: QueryKey) => {
      await queryClient.invalidateQueries({ queryKey })
    },

    updateList: <T extends DataWithId>(queryKey: QueryKey, newData: T) => {
      queryClient.setQueryData<T[] | undefined>(queryKey, (oldData) => {
        if (!oldData) return oldData
        return oldData.map((item) => (item.id === newData.id ? newData : item))
      })
    },

    updateSingleItem: <T extends DataWithId>(queryKey: QueryKey, newData: T) => {
      queryClient.setQueryData(queryKey, newData)
    },
  }
}
