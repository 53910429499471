import { get } from '@/services/get'
import { post } from '@/services/post'
import { put } from '@/services/put'
import { JobDescription, JobUpdateDescriptionBody } from '@/types/job'

/**
 * GET /jobs/:jobId/description
 **/
export function getJobsDescription(jobId: string) {
  return get<JobDescription>(`/jobs/${jobId}/description`)
}

/**
 * PUT /jobs/:jobId/description
 */
export function updateJobsDescription(jobId: string, body: JobUpdateDescriptionBody) {
  return put<JobUpdateDescriptionBody, JobDescription>(`/jobs/${jobId}/description`, body)
}

/**
 * POST /jobs/:jobId/description
 */
export function createJobDescription(jobId: string, body: { description: string }) {
  return post<{ description: string }, { description: string }>(`/jobs/${jobId}/description`, body)
}
