import { Eye } from 'lucide-react'

import { FilePreview } from '@/components/file-preview'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogDescription,
  DialogTitle,
  DialogHeader,
} from '@/components/ui/dialog'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { MimeType } from '@/types/application/documents'

interface DocumentModalProps {
  name: string
  url: string
  mimeType: MimeType
}

export function DocumentModal({ mimeType, name, url }: DocumentModalProps) {
  return (
    <Dialog>
      <DialogTrigger>
        <Tooltip>
          <TooltipTrigger asChild>
            <Eye className="text-muted-foreground" size={16} />
          </TooltipTrigger>
          <TooltipContent>See document</TooltipContent>
        </Tooltip>
      </DialogTrigger>
      <DialogContent className="h-[calc(100%-theme(spacing.32))] min-w-[calc(100%-theme(spacing.32))] overflow-hidden p-8">
        <DialogHeader>
          <DialogTitle className="hidden">{name}</DialogTitle>
          <DialogDescription className="hidden">{name}</DialogDescription>
        </DialogHeader>
        <div className="overflow-hidden rounded border">
          <FilePreview mimeType={mimeType} name={name} url={url} />
        </div>
      </DialogContent>
    </Dialog>
  )
}
