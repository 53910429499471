import { useState } from 'react'

import { useDebounce } from '@uidotdev/usehooks'
import { ActionMeta, components, GroupBase, OptionProps, SingleValue } from 'react-select'

import { Select } from '@/components/select'
import { Text } from '@/components/ui/text'
import { JOB_STATUSES_COLORS } from '@/constants'
import { cn } from '@/lib/utils'
import { JobSearch } from '@/modules/job/domain/entities/job.entity'
import { useSearchJobs } from '@/modules/job/presentation/hooks/useJobs'
import { getDistanceToNowStrict } from '@/utils/date'
import { formatJobLocations } from '@/utils/string'

export const JobSearchSelect = ({
  emptyState = 'No jobs found for',
  hideDate,
  isClearable,
  isDisabled,
  onChange,
  placeholder = 'Search for a job...',
}: {
  emptyState?: string
  placeholder?: string
  onChange: (newValue: SingleValue<JobSearch>, actionMeta: ActionMeta<JobSearch>) => void
  hideDate?: boolean
  isClearable?: boolean
  isDisabled?: boolean
}) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 300)
  const { data: jobs, isLoading } = useSearchJobs(debouncedQuery)

  const noOptionsMessage = (inputValue: string) => {
    if (isLoading) return 'Loading...'

    if (inputValue && inputValue === debouncedQuery && jobs?.length === 0) {
      return `${emptyState} "${inputValue}"`
    }
    return null
  }

  return (
    <Select
      className="w-full"
      components={{
        Option: (props) => <OptionComponent {...props} hideDate={hideDate} />,
      }}
      getOptionLabel={(option) => option.title}
      getOptionValue={(option) => option.id}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      menuPosition="absolute"
      noOptionsMessage={({ inputValue }) => noOptionsMessage(inputValue)}
      onChange={onChange}
      onInputChange={setQuery}
      options={jobs}
      placeholder={placeholder}
    />
  )
}

const OptionComponent = <
  Option extends JobSearch,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: OptionProps<Option, IsMulti, Group> & { hideDate?: boolean },
) => {
  const { data, hideDate } = props
  const borderColor = `border-${JOB_STATUSES_COLORS[data.status]}`

  return (
    <components.Option {...props}>
      <div className={cn('flex border-l-4 px-2', borderColor)}>
        <div className="flex-1">
          <Text weight="semibold">{data.title}</Text>
          <div className="flex flex-wrap gap-x-2">
            <Text size="xs">{formatJobLocations(data.jobLocations)}</Text>
            <Text size="xs" weight="semibold">
              • {data.organization.name}
            </Text>
          </div>
        </div>
        {!hideDate && (
          <Text size="xs" weight="semibold">
            {getDistanceToNowStrict(data.createdAt)}
          </Text>
        )}
      </div>
    </components.Option>
  )
}
