import { useState } from 'react'

import { useParams } from '@tanstack/react-router'
import { toast } from 'sonner'

import { MultiFileUpload } from '@/components/multi-file-upload'
import { Button } from '@/components/ui/button'
import { useCreateApplicationsDocuments } from '@/queries/use-applications-mutations'

export function FilesDropzone() {
  const [files, setFiles] = useState<File[]>([])
  const { applicationId } = useParams({ from: '/_authenticated/jobs_/$jobId/applications/$applicationId' })
  const { isPending, mutateAsync: createApplicationsDocuments } = useCreateApplicationsDocuments(applicationId)
  const promises: Promise<{ url: string }>[] = []

  const onUpload = async () => {
    if (files.length > 0) {
      files.forEach((file) => {
        const formData = new FormData()
        formData.append('file', file)
        promises.push(
          createApplicationsDocuments(
            { body: formData },
            {
              onSuccess: () => {
                toast.success('File uploaded successfully.')
              },
            },
          ),
        )
      })
    }

    await Promise.all(promises).then(() => {
      toast.success('All files uploaded successfully.')
      setFiles([])
    })
  }

  const removeFile = (file: File) => {
    setFiles((prev) => prev.filter((f) => f !== file))
  }

  return (
    <div className="flex flex-col gap-y-4">
      <MultiFileUpload
        files={files}
        isPending={isPending}
        onDrop={(files) => setFiles((prev) => [...prev, ...files])}
        removeFile={removeFile}
      />
      {!!files.length && (
        <Button className="self-center" isLoading={isPending} onClick={onUpload}>
          Upload files
        </Button>
      )}
    </div>
  )
}
