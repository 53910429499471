import { Text } from '@/components/ui/text'
import { useCountdown } from '@/hooks/use-countdown'

interface CountDownProps {
  refusedDate: Date
}

export function CountDown({ refusedDate }: CountDownProps) {
  const { completed, days, hours, minutes } = useCountdown({ targetDate: refusedDate })

  if (completed) return null

  return (
    <Text size="xs" variant="red">
      {!!days && `${days}d `}
      {!!hours && `${hours}h `}
      {!!minutes && `${minutes}m`}
    </Text>
  )
}
