import { get } from '@/services/get'
import { put } from '@/services/put'
import { JobCreateOrUpdateInformationsBody, JobInformations } from '@/types/job'

/**
 * URL : /jobs/:jobId/info
 */
export function getJobsInfo(jobId: string) {
  return get<JobInformations>(`/jobs/${jobId}/info`)
}

/**
 * URL : /jobs/:jobId/info
 */
export function updateJobsInfo(jobId: string, body: JobCreateOrUpdateInformationsBody) {
  return put<JobCreateOrUpdateInformationsBody, JobInformations>(`/jobs/${jobId}/info`, body)
}
