import { useMutation } from '@tanstack/react-query'

import { applicationsJobStagesQuery, applicationsLastActivitiesQuery } from '@/hooks/use-applications-queries'
import { useUpdateCache } from '@/hooks/useUpdateCache'
import { jobsBoardQuery } from '@/queries/use-jobs-queries'
import { RefuseApplication, UpdateStage } from '@/schemas/application.schema'
import { applicationService } from '@/services/applicationService'

const useRefuseApplication = (jobId: string) => {
  const { invalidateQueries } = useUpdateCache()

  return useMutation({
    mutationFn: ({ applicationId, data }: { applicationId: string; data: RefuseApplication }) =>
      applicationService.refuseApplication(applicationId, data),
    onSuccess: async () => {
      await invalidateQueries(jobsBoardQuery(jobId).queryKey)
    },
  })
}

const useUpdateApplicationStage = () => {
  const { invalidateQueries } = useUpdateCache()

  return useMutation({
    mutationFn: ({ applicationId, data }: { applicationId: string; data: UpdateStage }) =>
      applicationService.updateStage(applicationId, data),
    onSuccess: async ({ id }) => {
      await invalidateQueries(applicationsJobStagesQuery(id).queryKey)
      await invalidateQueries(applicationsLastActivitiesQuery(id).queryKey)
    },
  })
}

export { useRefuseApplication, useUpdateApplicationStage }
