import { ApiGateway } from '@/modules/api/api.gateway'
import { ApplicationAssign } from '@/modules/application/domain/entities/application.entity'
import { IApplicationRepository } from '@/modules/application/domain/repositories/application.repository'
import { Id } from '@/shared/domain/entities/common.entity'

import { CreateApplicationDto } from '../domain/schemas/create.schema'

export class ApplicationRepository implements IApplicationRepository {
  constructor(private api: ApiGateway) {}

  async assign(data: ApplicationAssign): Promise<Id> {
    return this.api.post<Id, ApplicationAssign>('/applications/assign', data)
  }

  async create(data: CreateApplicationDto): Promise<Id> {
    return this.api.post<Id, CreateApplicationDto>('/applications', data)
  }
}
