import { get } from '@/services/get'
import { post } from '@/services/post'
import { put } from '@/services/put'
import { Recruiter, RecruiterCreateOrUpdateBody, RecruiterCreateOrUpdateResponse } from '@/types/recruiter'

/**
 * GET /recruiters
 */
export function getRecruiters(view?: 'minimal') {
  return get<Recruiter[]>('/recruiters', {
    params: {
      output: view,
    },
  })
}

/**
 * POST /recruiters
 */
export function createRecruiter(body: RecruiterCreateOrUpdateBody) {
  return post<RecruiterCreateOrUpdateBody, RecruiterCreateOrUpdateResponse>('/recruiters', body)
}

/**
 * PUT /recruiters/:recruiterId
 */
export function updateRecruiter(recruiterId: string, body: RecruiterCreateOrUpdateBody) {
  return put<RecruiterCreateOrUpdateBody, RecruiterCreateOrUpdateResponse>(`/recruiters/${recruiterId}`, body)
}
