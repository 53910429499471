import apiClient from '@/lib/apiClient'
import { CreateCustomEmailToSend } from '@/schemas/emailToSend.schema'
import { IdResponse } from '@/types/common.types'
import { EmailToSend } from '@/types/emailsToSend.types'

import { API_ENDPOINTS } from '.'

export const emailToSendService = {
  createCustomEmailToSend: async (data: CreateCustomEmailToSend): Promise<IdResponse> => {
    const response = await apiClient.post<IdResponse>(`${API_ENDPOINTS.EMAILS_TO_SEND}/custom`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data
  },

  getEmailToSendById: async (id?: string): Promise<EmailToSend> => {
    const response = await apiClient.get<EmailToSend>(`${API_ENDPOINTS.EMAILS_TO_SEND}/${id}`)
    return response.data
  },
}
