import { ComponentPropsWithoutRef } from 'react'

import { cva, VariantProps } from 'class-variance-authority'
import * as Flags from 'country-flag-icons/react/3x2'

import { cn } from '@/lib/utils'

import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip'

const countryFlagVariants = cva('border', {
  defaultVariants: {
    size: 'default',
  },
  variants: {
    size: {
      default: 'w-4',
      lg: 'w-8',
    },
  },
})

interface CountryFlagProps extends VariantProps<typeof countryFlagVariants> {
  code: string
  name: string
  side?: ComponentPropsWithoutRef<typeof TooltipContent>['side']
  hasTooltip?: boolean
}

export function CountryFlag({ code, hasTooltip = true, name, side, size }: CountryFlagProps) {
  if (!code || !name) return null
  // eslint-disable-next-line import/namespace
  const Flag = Flags[code as keyof typeof Flags]

  if (hasTooltip) {
    return (
      <Tooltip>
        <TooltipTrigger>
          <Flag className={cn(countryFlagVariants({ size }))} />
        </TooltipTrigger>
        <TooltipContent side={side}>{name}</TooltipContent>
      </Tooltip>
    )
  }

  return <Flag className={cn(countryFlagVariants({ size }))} />
}
