import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'

import { MimeType } from '@/types/application/documents'

interface FilePreviewProps {
  url: string
  mimeType: MimeType
  name: string
}

export function FilePreview({ mimeType, name, url }: FilePreviewProps) {
  const renderDocument = () => {
    if (
      mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      mimeType === 'application/msword'
    ) {
      return (
        <DocViewer
          config={{
            header: {
              disableHeader: true,
            },
          }}
          documents={[{ fileType: mimeType, uri: url }]}
          pluginRenderers={DocViewerRenderers}
          style={{ height: '100%', width: '100%' }}
        />
      )
    }
    if (mimeType === 'application/pdf') {
      return <iframe className="size-full overflow-hidden border-none" src={url}></iframe>
    }
    if (mimeType === 'image/jpeg' || mimeType === 'image/png') {
      return <img alt={name} className="size-auto max-h-full max-w-full" src={url} />
    }
    return null
  }

  return <div className="flex size-full items-center justify-center">{renderDocument()}</div>
}
