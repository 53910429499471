import { createFileRoute } from '@tanstack/react-router'

import { PageHeaderSkeleton } from '@/components/skeleton/page-header-skeleton'
import { jobsInternalInformationsQuery } from '@/queries/use-jobs-queries'

export const Route = createFileRoute('/_authenticated/jobs_/$jobId_/edit/internal-informations')({
  loader: ({ context: { queryClient }, params: { jobId } }) =>
    queryClient.ensureQueryData(jobsInternalInformationsQuery(jobId)),

  pendingComponent: () => (
    <>
      <PageHeaderSkeleton title="Internal informations" />
    </>
  ),
})
