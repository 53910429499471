import { Extension } from '@tiptap/react'

export const NoLineBreak = Extension.create({
  addKeyboardShortcuts() {
    return {
      Enter: () => true,
    }
  },

  name: 'noLineBreak',
})
