import { PutOrPostJobStageValues, PutJobStageRefusalStrategyValues } from '@/schemas/jobs/jobId/stage'
import { axiosDelete } from '@/services/delete'
import { get } from '@/services/get'
import { post } from '@/services/post'
import { put } from '@/services/put'
import { UpdateAutomationActiveBody } from '@/types/automation'
import { CreateOrUpdateAutomationCommentBody } from '@/types/automation/create-or-update-comment'
import { CreateOrUpdateAutomationEmailCandidateBody } from '@/types/automation/create-or-update-email-candidate'
import { CreateOrUpdateAutomationEmailRecruiterBody } from '@/types/automation/create-or-update-email-recruiter'
import { CreateOrUpdateAutomationScheduleInterviewBody } from '@/types/automation/create-or-update-schedule-interview'
import { CreateOrUpdateAutomationTaskRecruiterBody } from '@/types/automation/create-or-update-task-recruiter'
import {
  JobSettingsStage,
  JobSettingsStageAutomation,
  JobSettingsStageAutomations,
  JobUpdateStagesOrderBody,
  JobUpdateStagesOrderResponse,
} from '@/types/job'

/**
 * URL : /jobs/:jobId/stages
 */
export function getJobsStages(id: string) {
  return get<JobSettingsStage>(`/jobs/${id}/stages`)
}

/**
 * URL : /jobs/:jobId/stages
 */
export function createJobsStage(jobId: string, body: PutOrPostJobStageValues) {
  return post<PutOrPostJobStageValues, { id: string }>(`/jobs/${jobId}/stages`, body)
}

/**
 * URL : /jobs/:jobId/stages
 */
export function updateJobsStagesOrder(jobId: string, body: JobUpdateStagesOrderBody) {
  return put<JobUpdateStagesOrderBody, JobUpdateStagesOrderResponse>(`/jobs/${jobId}/stages`, body)
}

/**
 * URL : /jobs/:jobId/stages/:stageId
 */
export function updateJobsStage(jobId: string, stageId: string, body: PutOrPostJobStageValues) {
  return put<PutOrPostJobStageValues, { id: string }>(`/jobs/${jobId}/stages/${stageId}`, body)
}

/**
 * URL : /jobs/:jobId/stages/:stageId
 */
export function deleteJobsStage(jobId: string, stageId: string) {
  return axiosDelete<{ id: string }>(`/jobs/${jobId}/stages/${stageId}`, { customErrorHandling: true })
}

/**
 * URL : /jobs/:jobId/stages/:stageId/refusal-strategy
 */
export function updateJobsStageRefusalStrategy(jobId: string, stageId: string, body: PutJobStageRefusalStrategyValues) {
  return put<PutJobStageRefusalStrategyValues, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/refusal-strategy`,
    body,
  )
}

/**
 * URL : /jobs/:jobId/stages/:stageId/automations
 */
export function getJobsStagesAutomations(jobId: string, stageId?: string) {
  return get<JobSettingsStageAutomations[]>(`/jobs/${jobId}/stages/${stageId}/automations`)
}

/**
 * URL : /jobs/:jobId/stages/:stageId/automations/:automationId
 */
export function getJobsStagesAutomation<T>(jobId: string, stageId?: string, automationId?: string) {
  return get<JobSettingsStageAutomation<T>>(`/jobs/${jobId}/stages/${stageId}/automations/${automationId}`)
}

/**
 * URL : /jobs/:jobId/stages/:stageId/automations/email-candidate
 */
export function createJobsStagesAutomationsEmailCandidate(
  jobId: string,
  stageId: string,
  body: CreateOrUpdateAutomationEmailCandidateBody,
) {
  return post<CreateOrUpdateAutomationEmailCandidateBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/email-candidate`,
    body,
  )
}

/**
 * URL : /jobs/:jobId/stages/:stageId/automations/email-candidate/:automationId
 */
export function updateJobsStagesAutomationsEmailCandidate({
  automationId,
  body,
  jobId,
  stageId,
}: {
  automationId: string
  jobId: string
  stageId: string
  body: CreateOrUpdateAutomationEmailCandidateBody
}) {
  return put<CreateOrUpdateAutomationEmailCandidateBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/email-candidate/${automationId}`,
    body,
  )
}

/**
 * URL : /jobs/:jobId/stages/:stageId/automations/email-recruiter
 */
export function createJobsStagesAutomationsEmailRecruiter(
  jobId: string,
  stageId: string,
  body: CreateOrUpdateAutomationEmailRecruiterBody,
) {
  return post<CreateOrUpdateAutomationEmailRecruiterBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/email-recruiter`,
    body,
  )
}

/**
 * URL : /jobs/:jobId/stages/:stageId/automations/email-recruiter/:automationId
 */
export function updateJobsStagesAutomationsEmailRecruiter({
  automationId,
  body,
  jobId,
  stageId,
}: {
  automationId: string
  jobId: string
  stageId: string
  body: CreateOrUpdateAutomationEmailRecruiterBody
}) {
  return put<CreateOrUpdateAutomationEmailRecruiterBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/email-recruiter/${automationId}`,
    body,
  )
}

/**
 * URL : /jobs/:jobId/stages/:stageId/automations/comment
 */
export function createJobsStagesAutomationsComment(
  jobId: string,
  stageId: string,
  body: CreateOrUpdateAutomationCommentBody,
) {
  return post<CreateOrUpdateAutomationCommentBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/comment`,
    body,
  )
}

/**
 * URL : /jobs/:jobId/stages/:stageId/automations/comment/:automationId
 */
export function updateJobsStagesAutomationsComment({
  automationId,
  body,
  jobId,
  stageId,
}: {
  automationId: string
  jobId: string
  stageId: string
  body: CreateOrUpdateAutomationCommentBody
}) {
  return put<CreateOrUpdateAutomationCommentBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/comment/${automationId}`,
    body,
  )
}

/**
 * URL : /jobs/:jobId/stages/:stageId/automations/task-recruiter
 */
export function createJobsStagesAutomationsTaskRecruiter(
  jobId: string,
  stageId: string,
  body: CreateOrUpdateAutomationTaskRecruiterBody,
) {
  return post<CreateOrUpdateAutomationTaskRecruiterBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/task-recruiter`,
    body,
  )
}

/**
 * URL : /jobs/:jobId/stages/:stageId/automations/task-recruiter/:automationId
 */
export function updateJobsStagesAutomationsTaskRecruiter({
  automationId,
  body,
  jobId,
  stageId,
}: {
  automationId: string
  jobId: string
  stageId: string
  body: CreateOrUpdateAutomationTaskRecruiterBody
}) {
  return put<CreateOrUpdateAutomationTaskRecruiterBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/task-recruiter/${automationId}`,
    body,
  )
}

/**
 * URL : /jobs/:jobId/stages/:stageId/automations/schedule-interview
 */
export function createJobsStagesAutomationsScheduleInterview(
  jobId: string,
  stageId: string,
  body: CreateOrUpdateAutomationScheduleInterviewBody,
) {
  return post<CreateOrUpdateAutomationScheduleInterviewBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/schedule-interview`,
    body,
  )
}

/**
 * URL : /jobs/:jobId/stages/:stageId/automations/schedule-interview/:automationId
 */
export function updateJobsStagesAutomationsScheduleInterview({
  automationId,
  body,
  jobId,
  stageId,
}: {
  automationId: string
  jobId: string
  stageId: string
  body: CreateOrUpdateAutomationScheduleInterviewBody
}) {
  return put<CreateOrUpdateAutomationScheduleInterviewBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/schedule-interview/${automationId}`,
    body,
  )
}

/**
 * URL : /jobs/:jobId/stages/:stageId/automations/:automationId
 */
export function deleteJobsStagesAutomations(jobId: string, stageId: string, automationId: string) {
  return axiosDelete<{ id: string }>(`/jobs/${jobId}/stages/${stageId}/automations/${automationId}`)
}

/**
 * URL : /jobs/:jobId/stages/:stageId/automations/:automationId/active
 */
export function updateJobsStagesAutomationsActive(
  jobId: string,
  stageId: string,
  automationId: string,
  body: UpdateAutomationActiveBody,
) {
  return put<UpdateAutomationActiveBody, { id: string }>(
    `/jobs/${jobId}/stages/${stageId}/automations/${automationId}/active`,
    body,
  )
}
