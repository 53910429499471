import { createFileRoute } from '@tanstack/react-router'

import { PageContent } from '@/components/page-content'
import { PageHeaderSkeleton } from '@/components/skeleton/page-header-skeleton'
import { TableSkeleton } from '@/components/ui/table'
import { aventaAdminEmailTemplatesQuery } from '@/store/queries/useAventaAdminQueries'

export const Route = createFileRoute('/_authenticated/settings/4v3ntAdm1n')({
  component: () => <div>Hello /_authenticated/settings/4v3ntAdm1n!</div>,
  loader: ({ context: { queryClient } }) => queryClient.ensureQueryData(aventaAdminEmailTemplatesQuery),
  pendingComponent: () => (
    <>
      <PageHeaderSkeleton cta title="Admin emails templates" />
      <PageContent>
        <TableSkeleton />
      </PageContent>
    </>
  ),
})
