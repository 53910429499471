import { z } from 'zod'

import { entitySchema } from '../../entity'

export const putJobDescriptionSchema = z.object({
  benefits: z.array(entitySchema),
  description: z.string().min(1, {
    message: 'Description is required.',
  }),

  hasVisibleParentOrganization: z.boolean(),
  jobLanguages: z.array(
    z.object({
      languageId: z.string().min(1, {
        message: 'Language is required.',
      }),
      level: z.string().min(1, {
        message: 'Language level is required.',
      }),
    }),
  ),
  jobSoftwares: z.array(
    z.object({
      level: z.string().min(1, {
        message: 'Software level is required.',
      }),
      softwareId: z.string().min(1, {
        message: 'Software is required.',
      }),
    }),
  ),

  organizationId: z.string().min(1, {
    message: 'Organization is required.',
  }),
  skills: z.array(entitySchema),
})

export type PutJobDescriptionValues = z.infer<typeof putJobDescriptionSchema>

export const putJobInformationsSchema = z
  .object({
    contractType: z.string(),
    endDate: z.date().nullable().optional(),
    experience: z.number().array(),
    jobLocations: z
      .object({
        frequency: z.string().min(1, {
          message: 'Frequency is required.',
        }),
        location: entitySchema,
        type: z.string().min(1, {
          message: 'Type is required.',
        }),
      })
      .array(),
    openPositions: z.coerce
      .number({
        invalid_type_error: 'Number of positions must be a number.',
        required_error: 'Number of positions is required.',
      })
      .min(1, 'Number of positions must be greater than 0.'),
    salaryCurrency: z.string().nullable().optional(),
    salaryMax: z.number().nullable(),
    salaryMin: z.number().nullable(),
    salaryPeriod: z.string().nullable().optional(),
    seniority: z.string(),
    startDate: z.date().nullable().optional(),
    title: z.string().min(1, 'Job title is required.'),
  })
  .refine(
    ({ endDate, startDate }) => {
      if (startDate && endDate) {
        return startDate < endDate
      }
      return true
    },
    {
      message: 'End date must be after start date.',
      path: ['endDate'],
    },
  )
  .refine(
    ({ endDate, startDate }) => {
      if (startDate && endDate) {
        return startDate < endDate
      }
      return true
    },
    {
      message: 'Start date must be before end date.',
      path: ['startDate'],
    },
  )

export type PutJobInformationsValues = z.infer<typeof putJobInformationsSchema>
export const putJobInformationsValidations = {
  contractType: putJobInformationsSchema.innerType().innerType().shape.contractType,
  endDate: putJobInformationsSchema.innerType().innerType().shape.endDate,
  experience: putJobInformationsSchema.innerType().innerType().shape.experience,
  jobLocations: putJobInformationsSchema.innerType().innerType().shape.jobLocations,
  openPositions: putJobInformationsSchema.innerType().innerType().shape.openPositions,
  salaryCurrency: putJobInformationsSchema.innerType().innerType().shape.salaryCurrency,
  salaryMax: putJobInformationsSchema.innerType().innerType().shape.salaryMax,
  salaryMin: putJobInformationsSchema.innerType().innerType().shape.salaryMin,
  salaryPeriod: putJobInformationsSchema.innerType().innerType().shape.salaryPeriod,
  seniority: putJobInformationsSchema.innerType().innerType().shape.seniority,
  startDate: putJobInformationsSchema.innerType().innerType().shape.startDate,
  title: putJobInformationsSchema.innerType().innerType().shape.title,
}

export const putJobInternalInformationsSchema = z.object({
  internalInformations: z.string().trim().min(1, 'Internal informations is required.'),
})

export type PutJobInternalInformationsValues = z.infer<typeof putJobInternalInformationsSchema>

export const putJobInternalInformationsValidations = {
  internalInformations: putJobInternalInformationsSchema.shape.internalInformations,
}
