import { get } from '@/services/get'
import { put } from '@/services/put'
import { GetJobInternalInformationsResponse, PutJobInternalInformationsBody } from '@/types/job'

/**
 * GET /jobs/:jobId/internal-informations
 */
export function getJobsInternalInformations(jobId: string) {
  return get<GetJobInternalInformationsResponse>(`/jobs/${jobId}/internal-informations`)
}

/**
 * PUT /jobs/:jobId/internal-informations
 */
export function updateJobsInternalInformations(jobId: string, body: PutJobInternalInformationsBody) {
  return put<PutJobInternalInformationsBody, { id: string }>(`/jobs/${jobId}/internal-informations`, body)
}
