import { zodResolver } from '@hookform/resolvers/zod'
import { useParams } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { useAuth } from '@/hooks/use-auth'
import { useCreateApplicationsInterviews } from '@/queries/use-applications-mutations'
import { postInterviewSchema, PostInterviewValues } from '@/schemas/interviews/create'

const TYPE_INFORMATION_MAP = {
  CALENDLY: 'The Applicant will receive you Calendar to pick a time slot.',
  DIRECT_CALL: 'The Applicant won’t receive any email.',
  SCHEDULED: 'The Applicant will receive an email to confirm the meeting.',
} as const

export const useCreateForm = ({ onClose }: { onClose: () => void }) => {
  const { applicationId } = useParams({ from: '/_authenticated/jobs_/$jobId/applications/$applicationId' })
  const { user } = useAuth()

  const form = useForm<PostInterviewValues>({
    defaultValues: {
      duration: 30,
      participant: user?.id,
      scheduledAt: undefined,
      timeStatus: '',
    },
    resolver: zodResolver(postInterviewSchema),
  })

  const { isPending, mutate: createApplicationsInterview } = useCreateApplicationsInterviews(applicationId)

  const { control, watch } = form

  const onSubmit = form.handleSubmit((values) => {
    let data = { ...values }

    if (values.timeStatus === 'DIRECT_CALL') {
      data = {
        timeStatus: values.timeStatus,
      }
    }

    if (values.timeStatus === 'CALENDLY') {
      data = {
        ...data,
        scheduledAt: undefined,
      }
    }

    if (values.timeStatus === 'SCHEDULED') {
      data = {
        ...data,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
    }

    createApplicationsInterview(
      { body: data },
      {
        onSuccess: () => {
          onClose()
          toast.success('Interview created')
        },
      },
    )
  })

  const timeStatus = watch('timeStatus')

  const information = TYPE_INFORMATION_MAP[timeStatus as keyof typeof TYPE_INFORMATION_MAP]

  const showScheduledAt = timeStatus === 'SCHEDULED'
  const showOptionFields = timeStatus && timeStatus !== 'DIRECT_CALL'

  return {
    control,
    form,
    information,
    isPending,
    onSubmit,
    showOptionFields,
    showScheduledAt,
  }
}
