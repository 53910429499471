import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { ConfigSelect } from '@/components/form/config-select'
import { DatetimepickerInput } from '@/components/form/datetimepicker-input'
import { DurationSelect } from '@/components/form/duration-select'
import { RecruiterSelect } from '@/components/form/recruiter-select'
import { Button } from '@/components/ui/button'
import { Form, FormControl } from '@/components/ui/form'
import { SheetClose, SheetFooter } from '@/components/ui/sheet'
import { postInterviewDefaultValidations as validations } from '@/schemas/interviews/create'
import { isBeforeToday } from '@/utils/date'

import { useCreateForm } from './useCreateForm'

interface CreateFormProps {
  onClose: () => void
}

export function CreateForm({ onClose }: CreateFormProps) {
  const { control, form, information, isPending, onSubmit, showOptionFields, showScheduledAt } = useCreateForm({
    onClose,
  })

  return (
    <Form {...form}>
      <form className="mt-8 flex flex-col gap-y-4" onSubmit={onSubmit}>
        <CustomFormField
          control={control}
          fieldType={FormFieldType.SKELETON}
          information={information}
          label="Type"
          name="timeStatus"
          renderSkeleton={(field) => (
            <FormControl>
              <ConfigSelect
                onChange={field.onChange}
                placeholder="Select interview type"
                type="interviewTimeStatuses"
                value={field.value}
              />
            </FormControl>
          )}
          validation={validations.timeStatus}
        />
        {showOptionFields && (
          <>
            <CustomFormField
              control={control}
              fieldType={FormFieldType.SKELETON}
              label="Participant"
              name="participant"
              renderSkeleton={(field) => (
                <FormControl>
                  <RecruiterSelect onChange={field.onChange} placeholder="Select participant..." value={field.value} />
                </FormControl>
              )}
              validation={validations.participant}
            />
            {showScheduledAt && (
              <CustomFormField
                control={control}
                fieldType={FormFieldType.SKELETON}
                label="Scheduled at"
                name="scheduledAt"
                renderSkeleton={(field) => (
                  <FormControl>
                    <DatetimepickerInput disabled={isBeforeToday} onChange={field.onChange} value={field.value} />
                  </FormControl>
                )}
                validation={validations.scheduledAt}
              />
            )}
            <CustomFormField
              control={control}
              fieldType={FormFieldType.SKELETON}
              label="Duration"
              name="duration"
              renderSkeleton={(field) => (
                <FormControl>
                  <DurationSelect onChange={field.onChange} value={field.value} />
                </FormControl>
              )}
              validation={validations.duration}
            />
          </>
        )}
        <SheetFooter className="mt-8">
          <SheetClose asChild>
            <Button disabled={isPending} variant="outline">
              Cancel
            </Button>
          </SheetClose>
          <Button isLoading={isPending} type="submit">
            Create
          </Button>
        </SheetFooter>
      </form>
    </Form>
  )
}
