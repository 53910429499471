import { queryOptions } from '@tanstack/react-query'

import { getRecruiters } from '@/api/recruiters'

/**
 * GET /recruiters
 */

export const recruitersQuery = queryOptions({
  queryFn: () => getRecruiters(),
  queryKey: ['recruiters'],
  staleTime: Infinity,
})

/**
 * GET /recruiters?view=minimal
 */
export const recruitersMinimalQuery = queryOptions({
  queryFn: () => getRecruiters('minimal'),
  queryKey: ['recruiters', 'minimal'],
  staleTime: Infinity,
})
