import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { PageContent } from '@/components/page-content'
import { PageHeaderSkeleton } from '@/components/skeleton/page-header-skeleton'
import { TableSkeleton } from '@/components/ui/table'
import { organizationsMinimalQuery } from '@/store/queries/useOrganizationQueries'

export const Route = createFileRoute('/_authenticated/settings/emails-templates')({
  loader: ({ context: { queryClient } }) => queryClient.ensureQueryData(organizationsMinimalQuery),
  loaderDeps: ({ search: { organizationId } }) => ({
    organizationId,
  }),
  pendingComponent: () => (
    <>
      <PageHeaderSkeleton cta title="Organizations" />
      <PageContent>
        <TableSkeleton />
      </PageContent>
    </>
  ),
  validateSearch: z.object({
    organizationId: z.string().optional(),
  }),
})
