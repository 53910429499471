import { get } from '@/services/get'
import { post } from '@/services/post'
import { InterviewApplication, CreateApplicationInterviewBody } from '@/types/application/interview'

/**
 * URL : /applications/:applicationId/interviews
 */
export function getApplicationsInterviews(applicationId: string) {
  return get<InterviewApplication>(`/applications/${applicationId}/interviews`)
}

/**
 * URL : /applications/:applicationId/interviews
 */
export function createApplicationsInterviews(applicationId: string, body: CreateApplicationInterviewBody) {
  return post<CreateApplicationInterviewBody, { id: string }>(`/applications/${applicationId}/interviews`, body)
}
