import { queryOptions, useQuery } from '@tanstack/react-query'

import { emailTemplateService } from '@/services/emailTemplateService'

export const organizationEmailTemplatesQuery = (organizationId: string) =>
  queryOptions({
    queryFn: () => emailTemplateService.getByOrganization(organizationId),
    queryKey: ['organizationEmailTemplates', organizationId],
  })

export const useOrganizationEmailTemplates = (organizationId: string) =>
  useQuery(organizationEmailTemplatesQuery(organizationId))

export const emailTemplateQuery = (id?: string) =>
  queryOptions({
    enabled: !!id,
    queryFn: () => emailTemplateService.getById(id),
    queryKey: ['emailTemplate', id],
  })

export const useEmailTemplate = (id?: string) => useQuery(emailTemplateQuery(id))
