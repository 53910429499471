import { queryOptions, useQuery } from '@tanstack/react-query'

import { refusalReasonService } from '@/services/refusalReasonService'

export const refusalReasonsQuery = queryOptions({
  queryFn: refusalReasonService.getAll,
  queryKey: ['refusalReasons'],
  staleTime: Infinity,
})

export const useRefusalReasons = () => useQuery(refusalReasonsQuery)
