import { queryOptions } from '@tanstack/react-query'

import { organizationService } from '@/services/organizationService'

export const organizationsQuery = queryOptions({
  queryFn: () => organizationService.getAll(undefined),
  queryKey: ['organizations'],
})

export const organizationsMinimalQuery = queryOptions({
  queryFn: () => organizationService.getAll('minimal'),
  queryKey: ['organizations', 'minimal'],
})
