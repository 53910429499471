import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { DelaySelect } from '@/components/form/selects/DelaySelect'
import { OrganizationEmailTemplatesSelect } from '@/components/form/selects/OrganizationEmailTemplatesSelect'
import { RefusalReasonsSelect } from '@/components/form/selects/RefusalReasonsSelect'
import { Tiptap } from '@/components/tiptap/tiptap'
import { Button } from '@/components/ui/button'
import { DialogClose, DialogFooter } from '@/components/ui/dialog'
import { Form, FormControl } from '@/components/ui/form'
import { Switch } from '@/components/ui/switch'
import { EMAIL_TEMPLATE_TAGS } from '@/constants/emailTemplateTags'

import { useRefusalReasonForm } from './useRefusalReasonForm'

interface Props {
  applicationId: string
  fromStageId?: string
  toStageId?: string
  jobId: string
  emailSendingDelay?: number
  emailTemplateId?: string
  refusalReasonId?: string
  customEmailToSendId?: string
}

export function RefusalReasonForm({
  applicationId,
  customEmailToSendId,
  emailSendingDelay,
  emailTemplateId,
  fromStageId,
  jobId,
  refusalReasonId,
  toStageId,
}: Props) {
  const { clearFields, control, form, handleSubmit, onSubmit, watch } = useRefusalReasonForm({
    applicationId,
    customEmailToSendId,
    emailSendingDelay,
    emailTemplateId,
    fromStageId,
    jobId,
    refusalReasonId,
    toStageId,
  })

  return (
    <Form {...form}>
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <CustomFormField
          control={control}
          fieldType={FormFieldType.SKELETON}
          label="Refusal reason"
          name="refusalReasonId"
          renderSkeleton={(field) => (
            <FormControl>
              <RefusalReasonsSelect onChange={field.onChange} value={field.value} />
            </FormControl>
          )}
        />

        <CustomFormField
          control={control}
          fieldType={FormFieldType.SKELETON}
          label="Send email"
          name="hasEmail"
          renderSkeleton={(field) => (
            <FormControl>
              <Switch
                checked={field.value}
                onCheckedChange={(value) => {
                  field.onChange(value)
                  if (!value) clearFields()
                }}
              />
            </FormControl>
          )}
        />

        {watch('hasEmail') && (
          <>
            <CustomFormField
              control={control}
              fieldType={FormFieldType.SKELETON}
              label="Email template"
              name="emailTemplateId"
              renderSkeleton={(field) => (
                <FormControl>
                  <OrganizationEmailTemplatesSelect onChange={field.onChange} value={field.value} />
                </FormControl>
              )}
            />
            <CustomFormField
              control={control}
              description="Type @ to display a list of variables and select them to insert into the text."
              descriptionTooltipContent={EMAIL_TEMPLATE_TAGS.join('\n')}
              fieldType={FormFieldType.SKELETON}
              label="Subject"
              name="subject"
              placeholder="Interview Invitation"
              renderSkeleton={(field) => (
                <Tiptap
                  extensions="input"
                  name={field.name}
                  onChange={field.onChange}
                  suggestionItems={EMAIL_TEMPLATE_TAGS}
                  value={field.value || ''}
                />
              )}
            />
            <CustomFormField
              control={control}
              description="Type @ to display a list of variables (applicant lastname, job title, etc...) and select them to insert into the text."
              descriptionTooltipContent={EMAIL_TEMPLATE_TAGS.join('\n')}
              fieldType={FormFieldType.SKELETON}
              label="Body"
              name="body"
              renderSkeleton={(field) => (
                <Tiptap
                  extensions="emailTemplate"
                  name={field.name}
                  onChange={field.onChange}
                  suggestionItems={EMAIL_TEMPLATE_TAGS}
                  value={field.value || ''}
                />
              )}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.SKELETON}
              label="Delay"
              name="emailSendingDelay"
              renderSkeleton={(field) => (
                <FormControl>
                  <DelaySelect onChange={field.onChange} value={field.value} />
                </FormControl>
              )}
            />
          </>
        )}
        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="outline">
              Cancel
            </Button>
          </DialogClose>
          <Button type="submit">Update</Button>
        </DialogFooter>
      </form>
    </Form>
  )
}
