import apiClient from '@/lib/apiClient'
import { CreateAventaAdminEmailTemplate, UpdateAventaAdminEmailTemplate } from '@/schemas/aventaAdmin.schema'
import { AventaAdminEmailTemplate, AventaAdminEmailTemplateList } from '@/types/aventaAdmin.types'

import { API_ENDPOINTS } from '.'

export const aventaAdminService = {
  createEmailTemplate: async (data: CreateAventaAdminEmailTemplate): Promise<AventaAdminEmailTemplate> => {
    const response = await apiClient.post<AventaAdminEmailTemplate>(
      `${API_ENDPOINTS.AVENTA_ADMIN}/email-templates`,
      data,
    )
    return response.data
  },

  getEmailTemplateById: async (id?: string): Promise<AventaAdminEmailTemplate> => {
    const response = await apiClient.get<AventaAdminEmailTemplate>(
      `${API_ENDPOINTS.AVENTA_ADMIN}/email-templates/${id}`,
    )
    return response.data
  },

  getEmailTemplates: async (): Promise<AventaAdminEmailTemplateList[]> => {
    const response = await apiClient.get<AventaAdminEmailTemplateList[]>(
      `${API_ENDPOINTS.AVENTA_ADMIN}/email-templates`,
    )
    return response.data
  },

  updateEmailTemplate: async (id: string, data: UpdateAventaAdminEmailTemplate): Promise<AventaAdminEmailTemplate> => {
    const response = await apiClient.put<AventaAdminEmailTemplate>(
      `${API_ENDPOINTS.AVENTA_ADMIN}/email-templates/${id}`,
      data,
    )
    return response.data
  },
}
