import { HeadingOptions } from '@tiptap/extension-heading'
import { type Editor } from '@tiptap/react'
import {
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  Heading4Icon,
  Heading5Icon,
  Heading6Icon,
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  ListOrderedIcon,
  LinkIcon,
  ListIcon,
} from 'lucide-react'

import { Toggle } from '../ui/toggle'

interface TiptapToolbarProps {
  editor: Editor | null
}

export function TiptapToolbar({ editor }: TiptapToolbarProps) {
  if (!editor) return null

  const setLink = () => {
    const previousUrl = editor.getAttributes('link').href as string
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
  }

  const isExtensionLoaded = (extensionName: string) => {
    return editor.extensionManager.extensions.find((ext) => ext.name === extensionName)
  }

  const toolbarItems = []

  if (isExtensionLoaded('heading')) {
    const { levels } = editor.extensionManager.extensions.find((ext) => ext.name === 'heading')
      ?.options as HeadingOptions
    levels.forEach((level) => {
      const Icon = [Heading1Icon, Heading2Icon, Heading3Icon, Heading4Icon, Heading5Icon, Heading6Icon][level - 1]

      toolbarItems.push({
        action: () => editor.chain().focus().toggleHeading({ level }).run(),
        ariaLabel: `Heading ${level}`,
        icon: Icon,
        isActive: editor.isActive('heading', { level }),
      })
    })
  }

  if (isExtensionLoaded('bold')) {
    toolbarItems.push({
      action: () => editor.chain().focus().toggleBold().run(),
      ariaLabel: 'Bold',
      icon: BoldIcon,
      isActive: editor.isActive('bold'),
    })
  }

  if (isExtensionLoaded('italic')) {
    toolbarItems.push({
      action: () => editor.chain().focus().toggleItalic().run(),
      ariaLabel: 'Italic',
      icon: ItalicIcon,
      isActive: editor.isActive('italic'),
    })
  }

  if (isExtensionLoaded('underline')) {
    toolbarItems.push({
      action: () => editor.chain().focus().toggleUnderline().run(),
      ariaLabel: 'Underline',
      icon: UnderlineIcon,
      isActive: editor.isActive('underline'),
    })
  }

  if (isExtensionLoaded('bulletList')) {
    toolbarItems.push({
      action: () => editor.chain().focus().toggleBulletList().run(),
      ariaLabel: 'Bullet list',
      icon: ListIcon,
      isActive: editor.isActive('bulletList'),
    })
  }

  if (isExtensionLoaded('orderedList')) {
    toolbarItems.push({
      action: () => editor.chain().focus().toggleOrderedList().run(),
      ariaLabel: 'Bullet list',
      icon: ListOrderedIcon,
      isActive: editor.isActive('orderedList'),
    })
  }

  if (isExtensionLoaded('link')) {
    toolbarItems.push({
      action: () => setLink(),
      ariaLabel: 'Link',
      icon: LinkIcon,
      isActive: editor.isActive('link'),
    })
  }

  if (toolbarItems.length === 0) return null

  return (
    <div className="flex gap-x-1 rounded-t-md border border-input bg-transparent p-1">
      {toolbarItems.map(({ action, ariaLabel, icon: Icon, isActive }, index) => (
        <Toggle aria-label={ariaLabel} key={index} onPressedChange={action} pressed={isActive} size="sm">
          <Icon size={16} />
        </Toggle>
      ))}
    </div>
  )
}
