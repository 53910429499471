import { CalendarIcon } from 'lucide-react'

import { Text } from '@/components/ui/text'
import { cn } from '@/lib/utils'
import { InterviewStatus } from '@/types/application/interview'
import { getFormattedDate, getFormattedDateTime } from '@/utils/date'

interface Props {
  status: keyof typeof InterviewStatus
  isPast: boolean
  date: string
}

export function InterviewInfo({ date, isPast, status }: Props) {
  if (status === 'TO_SCHEDULE') {
    return <Text size="xs">Interview invitation sent {getFormattedDate(date)}</Text>
  }

  if (status === 'SCHEDULED' || status === 'RE_SCHEDULED') {
    return (
      <div className="inline-flex gap-x-1">
        <CalendarIcon className={cn('text-green', isPast && 'text-destructive')} size={16} />
        <Text size="xs" variant={isPast ? 'red' : 'green'}>
          {getFormattedDateTime(date)}
        </Text>
      </div>
    )
  }

  return null
}
