import { get } from '@/services/get'
import { JobTitle } from '@/types/jobTitle'

/**
 * / GET /job-titles/search
 */
export function getJobTitlesSearch(query: string) {
  return get<JobTitle[]>('/job-titles/search', {
    params: {
      q: query,
    },
  })
}
