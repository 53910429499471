import { createContext } from 'react'

import { useLocalStorage } from '@uidotdev/usehooks'
import { jwtDecode } from 'jwt-decode'

import { login as apiLogin } from '@/api/auth'
import { useJobFilters } from '@/hooks/use-job-filters'
import { LoginValues } from '@/schemas/auth'

export type User = {
  lastname: string
  firstname: string
  accessToken: string
  email: string
  id: string
  organizationIds: string[]
}

export interface AuthContextProps {
  login: (values: LoginValues) => Promise<void>
  isAuthenticated: () => boolean
  user?: User
  logout: () => void
}

type JwtPayload = {
  id: string
  email: string
  firstname: string
  lastname: string
  iat: number
  exp: number
  organizationIds: string[]
}

export const AuthContext = createContext<AuthContextProps | null>(null)

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useLocalStorage<User | undefined>('user', undefined)
  const { setJobFilters } = useJobFilters()

  const isAuthenticated = () => !!localStorage.getItem('user')

  const logout = () => {
    setUser(undefined)
    localStorage.clear()
  }

  const login = async (values: LoginValues) => {
    const { accessToken } = await apiLogin(values)
    const { email, firstname, id, lastname, organizationIds } = jwtDecode<JwtPayload>(accessToken)

    setUser({ accessToken, email, firstname, id, lastname, organizationIds })
    setJobFilters({ recruiterIds: [id] })
  }

  return <AuthContext.Provider value={{ isAuthenticated, login, logout, user }}>{children}</AuthContext.Provider>
}
