import { useState } from 'react'

import { Mail } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { useBulkActions } from '@/hooks/use-bulk-actions'

import { SendEmailForm } from './send-email-form'

export function SendEmailAction() {
  const [open, onOpenChange] = useState(false)

  const { selected } = useBulkActions()
  const hasSelected = selected.length > 0

  return (
    <>
      <Button disabled={!hasSelected} onClick={() => onOpenChange(true)}>
        <Mail size={16} />
        Send email
      </Button>

      <Dialog onOpenChange={onOpenChange} open={open}>
        <DialogContent className="max-h-[calc(100vh-4rem)] w-full max-w-screen-lg overflow-auto">
          <DialogHeader>
            <DialogTitle>Edit email template</DialogTitle>
          </DialogHeader>
          <SendEmailForm onClose={() => onOpenChange(false)} />
        </DialogContent>
      </Dialog>
    </>
  )
}
