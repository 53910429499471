import { Select } from '@/components/select'
import { useOrganizationId } from '@/hooks/use-organization-id'
import { useOrganizationEmailTemplates } from '@/store/queries/useEmailTemplateQueries'

interface Props {
  value?: string | null
  onChange: (value: string) => void
}

export function OrganizationEmailTemplatesSelect({ onChange, value }: Props) {
  // TODO: Maybe we should use the organizationId from the parent component
  const { organizationId } = useOrganizationId()
  const { data: emailTemplates, isLoading } = useOrganizationEmailTemplates(organizationId)

  return (
    <Select
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isLoading={isLoading}
      menuPosition="absolute"
      onChange={(value) => value && onChange(value.id)}
      options={emailTemplates}
      placeholder="Select email template..."
      value={emailTemplates?.find((opt) => opt.id === value)}
    />
  )
}
