import { JobSearch, JobStage } from '@/modules/job/domain/entities/job.entity'
import { IJobRepository } from '@/modules/job/domain/interfaces/job.interface'

export class JobUseCase {
  constructor(private jobRepository: IJobRepository) {}

  async search(query: string): Promise<JobSearch[]> {
    return this.jobRepository.search(query)
  }

  async getStages(jobId: string): Promise<JobStage> {
    return this.jobRepository.getStages(jobId)
  }
}
