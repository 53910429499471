import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { ConfigSelect } from '@/components/form/config-select'
import { JobTitleSelect } from '@/components/form/job-title-select'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Form } from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import { Slider } from '@/components/ui/slider'
import { useCreateJob, useUpdateJobsInfo } from '@/queries/use-jobs-mutations'
import {
  PutJobInformationsValues,
  putJobInformationsSchema,
  putJobInformationsValidations as validations,
} from '@/schemas/jobs/jobId/edit'
import { JobInformations } from '@/types/job'
import { isBeforeToday } from '@/utils/date'

import { EMPTY_LOCATION, LocationFields } from './location-fields'
import { getJobInformationValuesFormated } from './utils'

interface CreateOrUpdateProps {
  jobInformations?: JobInformations
}

export function CreateOrUpdateForm({ jobInformations }: CreateOrUpdateProps) {
  const { isPending: isUpdateJobsInformationsPending, mutate: updateJobsInfo } = useUpdateJobsInfo()
  const { isPending: isCreateJobsPending, mutate: createJobs } = useCreateJob()
  const navigate = useNavigate()

  const form = useForm<PutJobInformationsValues>({
    defaultValues: {
      contractType: jobInformations?.contractType,
      endDate: jobInformations?.endDate ? new Date(jobInformations?.endDate) : null,
      experience: [jobInformations?.experienceMin ?? 4, jobInformations?.experienceMax ?? 8],
      jobLocations: jobInformations?.jobLocations.length
        ? jobInformations?.jobLocations.map((location) => ({
            frequency: location.frequency,
            location: location.location,
            type: location.type,
          }))
        : EMPTY_LOCATION,
      openPositions: jobInformations?.openPositions ?? 1,
      salaryCurrency: jobInformations?.salaryCurrency,
      salaryMax: jobInformations?.salaryMax ?? null,
      salaryMin: jobInformations?.salaryMin ?? null,
      salaryPeriod: jobInformations?.salaryPeriod,
      seniority: jobInformations?.seniority,
      startDate: jobInformations?.startDate ? new Date(jobInformations?.startDate) : null,
      title: jobInformations?.title,
    },
    resolver: zodResolver(putJobInformationsSchema),
  })

  const { control, handleSubmit, watch } = form

  const onSubmit = (values: PutJobInformationsValues) => {
    const body = getJobInformationValuesFormated(values)

    if (jobInformations) {
      updateJobsInfo(
        { body, jobId: jobInformations.id },
        {
          onSuccess: () => {
            toast.success('Job informations successfully updated.')
          },
        },
      )
    } else {
      createJobs(body, {
        onSuccess: (data) => {
          toast.success('Job informations successfully updated.')
          navigate({ params: { jobId: data.id }, to: '/jobs/$jobId/edit/description' }).catch(console.error)
        },
      })
    }
  }

  const startDate = watch('startDate')

  return (
    <Card className="p-8">
      <Form {...form}>
        <form className="flex max-w-xl flex-col gap-y-4" onSubmit={handleSubmit(onSubmit)}>
          <CustomFormField
            control={control}
            fieldType={FormFieldType.SKELETON}
            label="Job title"
            name="title"
            renderSkeleton={(field) => <JobTitleSelect onChange={field.onChange} value={field.value} />}
            validation={validations.title}
          />
          <CustomFormField
            control={control}
            fieldType={FormFieldType.NUMBER_INPUT}
            label="Number of positions"
            name="openPositions"
            placeholder="1"
            validation={validations.openPositions}
          />
          <CustomFormField
            control={control}
            description="Select the minimum and maximum years of experience required for the job"
            fieldType={FormFieldType.SKELETON}
            label="Experience"
            name="experience"
            renderSkeleton={(field) => (
              <div className="pb-4 pt-10">
                <Slider
                  defaultValue={field.value}
                  max={20}
                  min={0}
                  minStepsBetweenThumbs={1}
                  onValueChange={field.onChange}
                  step={1}
                />
              </div>
            )}
            validation={validations.experience}
          />
          <CustomFormField
            control={control}
            fieldType={FormFieldType.SKELETON}
            label="Seniority"
            name="seniority"
            renderSkeleton={(field) => (
              <ConfigSelect onChange={field.onChange} type="seniorities" value={field.value} />
            )}
            validation={validations.seniority}
          />
          <CustomFormField
            control={control}
            fieldType={FormFieldType.SKELETON}
            label="Contract type"
            name="contractType"
            renderSkeleton={(field) => (
              <ConfigSelect onChange={field.onChange} type="contractTypes" value={field.value} />
            )}
            validation={validations.contractType}
          />
          <div className="flex gap-x-4">
            <CustomFormField
              control={control}
              fieldType={FormFieldType.DATE_PICKER}
              label="Start date"
              name="startDate"
              tooltipContent={
                startDate && isBeforeToday(startDate)
                  ? 'When the start date is past,\nTalents will see ASAP'
                  : undefined
              }
              validation={validations.startDate}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.DATE_PICKER}
              label="End date"
              name="endDate"
              validation={validations.endDate}
            />
          </div>

          <div className="flex flex-col gap-y-2">
            <Label>Salary</Label>
            <div className="grid grid-cols-4 gap-x-2">
              <CustomFormField
                control={control}
                fieldType={FormFieldType.NUMBER_INPUT}
                name="salaryMin"
                placeholder="Minimum"
                validation={validations.salaryMin}
              />
              <CustomFormField
                control={control}
                fieldType={FormFieldType.NUMBER_INPUT}
                name="salaryMax"
                placeholder="Maximum"
                validation={validations.salaryMax}
              />
              <CustomFormField
                control={control}
                fieldType={FormFieldType.SKELETON}
                name="salaryPeriod"
                renderSkeleton={(field) => (
                  <ConfigSelect onChange={field.onChange} type="salaryPeriods" value={field.value} />
                )}
                validation={validations.salaryPeriod}
              />
              <CustomFormField
                control={control}
                fieldType={FormFieldType.SKELETON}
                name="salaryCurrency"
                renderSkeleton={(field) => (
                  <ConfigSelect onChange={field.onChange} type="salaryCurrencies" value={field.value} />
                )}
                validation={validations.salaryCurrency}
              />
            </div>
          </div>

          <LocationFields control={control} />

          <Button
            className="min-w-40 self-center"
            isLoading={isUpdateJobsInformationsPending || isCreateJobsPending}
            type="submit"
          >
            {jobInformations ? 'Update' : 'Continue to next step'}
          </Button>
        </form>
      </Form>
    </Card>
  )
}
