import { useState } from 'react'

import { useParams } from '@tanstack/react-router'
import { ArrowRightLeft } from 'lucide-react'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { useBulkActions } from '@/hooks/use-bulk-actions'
import { useUpdateJobBulkApplications } from '@/queries/use-jobs-mutations'

import { StageSelect } from '../stage-select'

export function MoveToStageAction() {
  const [open, setOpen] = useState(false)
  const { jobId } = useParams({ from: '/_authenticated/jobs_/$jobId' })
  const { clearSelected, selected, toggleOpen } = useBulkActions()
  const hasSelected = selected.length > 0

  const { mutate: updateJobBulkApplications } = useUpdateJobBulkApplications(jobId)

  const moveToStage = (value: string) => {
    updateJobBulkApplications(
      {
        action: 'MOVE_TO_STAGE',
        applicationIds: selected,
        value: {
          stageId: value,
        },
      },
      {
        onSuccess: () => {
          toast.success(`${selected.length > 1 ? 'Applicants' : 'Applicant'} moved to stage successfully.`)
          clearSelected()
          setOpen(false)
          toggleOpen()
        },
      },
    )
  }

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <PopoverTrigger asChild>
        <Button disabled={!hasSelected} onClick={() => setOpen(true)}>
          <ArrowRightLeft size={16} />
          Move to stage
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <StageSelect
          onChange={(data) => {
            if (data?.value) moveToStage(data.value)
          }}
        />
      </PopoverContent>
    </Popover>
  )
}
