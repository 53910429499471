import { useLocalStorage } from '@uidotdev/usehooks'

import { JobFilterValues } from '@/schemas/job'

export const JOB_FILTERS_KEY = 'jobFilters'

export function useJobFilters() {
  const [jobFilters, setJobFilters] = useLocalStorage<Omit<JobFilterValues, 'page'>>(JOB_FILTERS_KEY, undefined)

  return { jobFilters, setJobFilters }
}
