import { z } from 'zod'

export const applicationAssignSchema = z.object({
  applicantId: z.string(),
  isForced: z.boolean().optional(),
  jobId: z.string(),
  source: z.string().optional(),
  stageId: z.string().optional(),
  typeOfSource: z.string(),
})

export type ApplicationAssignValues = z.infer<typeof applicationAssignSchema>
