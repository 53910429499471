import { get } from '@/services/get'
import { put } from '@/services/put'
import { JobStatusResponse } from '@/types/job/status'
import { JobUpdateStatusBody } from '@/types/job/update-status'

/**
 * URL : /jobs/:jobId/status
 */
export function getJobsStatus(jobId: string) {
  return get<JobStatusResponse>(`/jobs/${jobId}/status`)
}

/**
 * URL : /jobs/:jobId/status
 */
export function updateJobsStatus(jobId: string, body: JobUpdateStatusBody) {
  return put<{ status: string }, { id: string }>(`/jobs/${jobId}/status`, body)
}
