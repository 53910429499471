import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { PageContent } from '@/components/page-content'
import { PageHeaderSkeleton } from '@/components/skeleton/page-header-skeleton'
import { TableSkeleton } from '@/components/ui/table'
import { jobsStagesQuery } from '@/queries/use-jobs-queries'

export const Route = createFileRoute('/_authenticated/jobs_/$jobId_/edit/stages')({
  loader: ({ context: { queryClient }, params: { jobId } }) => queryClient.ensureQueryData(jobsStagesQuery(jobId)),
  pendingComponent: () => (
    <>
      <PageHeaderSkeleton cta title="Stages" />
      <PageContent>
        <TableSkeleton />
      </PageContent>
    </>
  ),
  validateSearch: z.object({
    automationId: z.string().optional(),
    stageId: z.string().optional(),
    step: z
      .enum(['comment', 'create', 'email-candidate', 'email-recruiter', 'schedule-interview', 'show', 'task-recruiter'])
      .optional(),
  }),
})
