import { z } from 'zod'

export const postInterviewSchema = z
  .object({
    duration: z.number().optional(),
    participant: z.string().min(1, 'Participant is required').optional(),
    scheduledAt: z.date().optional(),
    timeStatus: z.string().min(1, 'Time status is required.'),
    timeZone: z.string().optional(),
    videoService: z.string().optional(),
  })
  .refine((data) => !(data.timeStatus === 'SCHEDULED' && !data.scheduledAt), {
    message: 'Scheduled at is required for scheduled interview.',
    path: ['scheduledAt'],
  })
  .refine((data) => !(data.timeStatus !== 'DIRECT_CALL' && !data.participant), {
    message: 'Partipant is required.',
    path: ['participant'],
  })
  .refine((data) => !(data.timeStatus !== 'DIRECT_CALL' && !data.duration), {
    message: 'Duration is required.',
    path: ['duration'],
  })

export type PostInterviewValues = z.infer<typeof postInterviewSchema>
export const postInterviewDefaultValidations = {
  duration: postInterviewSchema.innerType().innerType().innerType().shape.duration,
  participant: postInterviewSchema.innerType().innerType().innerType().shape.participant,
  scheduledAt: postInterviewSchema.innerType().innerType().innerType().shape.scheduledAt,
  timeStatus: postInterviewSchema.innerType().innerType().innerType().shape.timeStatus,
  videoService: postInterviewSchema.innerType().innerType().innerType().shape.videoService,
}
