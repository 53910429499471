import apiClient from '@/lib/apiClient'
import { CreateOrganization, UpdateOrganization } from '@/schemas/organization.schema'
import { Organization, OrganizationExtended, OrganizationMinimal } from '@/types/organization.types'

import { API_ENDPOINTS } from '.'

/*
 ** getAll : GET '/organizations'
 ** createOrganization : POST '/organizations'
 ** updateOrganization : PUT '/organizations/:id'
 */

type GetAllReturn<T> = T extends 'minimal' ? OrganizationMinimal[] : OrganizationExtended[]

export const organizationService = {
  createOrganization: async (data: CreateOrganization): Promise<Organization> => {
    const response = await apiClient.post<Organization>(API_ENDPOINTS.ORGANIZATIONS, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  },

  getAll: async <T extends 'minimal' | undefined>(view?: T): Promise<GetAllReturn<T>> => {
    const response = await apiClient.get<GetAllReturn<T>>(API_ENDPOINTS.ORGANIZATIONS, {
      params: {
        output: view,
      },
    })
    return response.data
  },

  updateOrganization: async (id: string, data: UpdateOrganization): Promise<Organization> => {
    const response = await apiClient.put<Organization>(`${API_ENDPOINTS.ORGANIZATIONS}/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  },
}
