import { useCallback, useEffect, useRef } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { RefuseApplicationForm, RefuseApplication, applicationSchemas } from '@/schemas/application.schema'
import { useRefuseApplication, useUpdateApplicationStage } from '@/store/mutations/useApplicationMutations'
import { useEmailsToSend } from '@/store/queries/useEmailsToSendQueries'
import { useEmailTemplate } from '@/store/queries/useEmailTemplateQueries'
import {
  convertMentionHtmlToVariables,
  convertVariablesToMentionHtml,
  removeHTMLTags,
  replaceAtSymbolWithCurlyBraces,
} from '@/utils/string'

import { useRefusalReasonModal } from './RefusalReasonModalProvider'

interface UseRefusalReasonFormProps {
  applicationId: string
  fromStageId?: string
  toStageId?: string
  jobId: string
  emailSendingDelay?: number
  emailTemplateId?: string
  refusalReasonId?: string
  customEmailToSendId?: string
}

export function useRefusalReasonForm({
  applicationId,
  customEmailToSendId,
  emailSendingDelay,
  emailTemplateId,
  fromStageId,
  jobId,
  refusalReasonId,
  toStageId,
}: UseRefusalReasonFormProps) {
  const form = useForm<RefuseApplicationForm>({
    defaultValues: {
      body: '',
      emailSendingDelay: emailSendingDelay ?? 2,
      emailTemplateId,
      hasEmail: !!emailTemplateId || !!customEmailToSendId,
      refusalReasonId,
      subject: '',
    },
    resolver: zodResolver(applicationSchemas.refuse),
  })

  const { control, handleSubmit, setValue, watch } = form

  const { closeModal } = useRefusalReasonModal()
  const { mutate: refuseApplicationMutation } = useRefuseApplication(jobId)
  const { mutate: updateApplicationStage } = useUpdateApplicationStage()

  const { data: emailTemplate } = useEmailTemplate(watch('emailTemplateId'))
  const { data: emailsToSend } = useEmailsToSend(customEmailToSendId)

  const emailsToSendApplied = useRef(false)

  const defaultHasEmail = !!emailTemplateId || !!customEmailToSendId

  const updateFormWithEmailContent = useCallback(
    (body: string, subject: string) => {
      setValue('body', convertVariablesToMentionHtml(body))
      setValue('subject', convertVariablesToMentionHtml(subject))
    },
    [setValue],
  )

  useEffect(() => {
    if (emailsToSend?.body && emailsToSend?.subject && !emailsToSendApplied.current) {
      updateFormWithEmailContent(emailsToSend.body, emailsToSend.subject)
      emailsToSendApplied.current = true
      return
    }

    if (emailTemplate?.body && emailTemplate?.subject && (!customEmailToSendId || emailsToSendApplied.current)) {
      updateFormWithEmailContent(emailTemplate.body, emailTemplate.subject)
    }
  }, [emailTemplate, emailsToSend, customEmailToSendId, updateFormWithEmailContent])

  const clearFields = () => {
    setValue('body', '')
    setValue('emailTemplateId', '')
    setValue('subject', '')
    setValue('emailSendingDelay', 0)
  }

  const refuseApplication = (data: RefuseApplication) => {
    refuseApplicationMutation(
      { applicationId, data },
      {
        onSuccess: () => {
          toast.success('Refusal reason updated.')
          closeModal()
        },
      },
    )
  }

  const onSubmit = (values: RefuseApplicationForm) => {
    const { hasEmail, ...rest } = values
    const data = hasEmail
      ? {
          body: replaceAtSymbolWithCurlyBraces(convertMentionHtmlToVariables(rest.body)),
          emailSendingDelay: rest.emailSendingDelay,
          emailTemplateId: rest.emailTemplateId,
          refusalReasonId: rest.refusalReasonId,
          subject: removeHTMLTags(convertMentionHtmlToVariables(rest.subject)),
        }
      : {
          customEmailToSendId: defaultHasEmail ? customEmailToSendId : undefined,
          refusalReasonId: rest.refusalReasonId,
        }

    if (fromStageId && toStageId) {
      updateApplicationStage(
        {
          applicationId,
          data: {
            fromStageId,
            toStageId,
          },
        },
        {
          onSuccess: () => {
            toast.success('Application stage updated.')
            refuseApplication(data)
          },
        },
      )

      return
    }

    refuseApplication(data)
  }

  return {
    clearFields,
    control,
    form,
    handleSubmit,
    onSubmit,
    watch,
  }
}
