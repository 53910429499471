import { z } from 'zod'

export const jobFilterSchema = z.object({
  organizationIds: z.string().array().optional(),
  page: z.number().catch(1).optional(),
  recruiterIds: z.string().array().optional(),
  statuses: z.string().array().optional(),
})

export type JobFilterValues = z.infer<typeof jobFilterSchema>

export const editJobsRecruitersSchema = z.object({
  recruiters: z.string().array().nonempty({
    message: 'At least one recruiter is required',
  }),
})

export const validations = {
  recruiters: editJobsRecruitersSchema.shape.recruiters,
}

export type EditJobsRecruitersValues = z.infer<typeof editJobsRecruitersSchema>
