import { queryOptions } from '@tanstack/react-query'

import { getNationalities } from '@/api/nationalities'

/**
 * GET /nationalities
 */
export const nationalitiesQuery = queryOptions({
  queryFn: () => getNationalities(),
  queryKey: ['nationalities'],
  staleTime: Infinity,
})
