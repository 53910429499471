import { isValidPhoneNumber } from 'react-phone-number-input'
import { z } from 'zod'

export const putApplicantSchema = z.object({
  applicationSource: z.string().optional().nullable(),
  applicationTypeOfSource: z.string().optional().nullable(),
  availability: z.date({ invalid_type_error: 'Availability must be a date' }).optional().nullable(),
  city: z.string().trim().optional(),
  countryId: z.string().min(1, 'Country is required.'),
  currentCompany: z.string().trim().optional(),
  currentJob: z.string().trim().optional(),
  email: z.string().trim().email().min(1, 'Email is required.'),
  expectedContractType: z.string().optional().nullable(),
  expectedSalary: z.number().optional().nullable(),
  expectedSalaryCurrency: z.string().nullable().optional(),
  expectedSalaryPeriod: z.string().nullable().optional(),
  firstname: z.string().trim().min(1, 'First name is required.'),
  lastname: z.string().trim().min(1, 'Last name is required.'),
  linkedinUrl: z
    .string()
    .regex(/^https:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/, 'URL must start with https://')
    .or(z.literal(''))
    .optional(),
  nationalityId: z.string().min(1, 'Nationality is required.'),
  phone: z.string().refine((value) => isValidPhoneNumber(value), { message: 'Invalid phone number.' }),
  source: z.string().optional().nullable(),
  typeOfSource: z.string().optional().nullable(),
  yearOfExperience: z.number().optional().nullable(),
})

export type PutApplicantValues = z.infer<typeof putApplicantSchema>

export const validations = {
  applicationSource: putApplicantSchema.shape.applicationSource,
  applicationTypeOfSource: putApplicantSchema.shape.applicationTypeOfSource,
  availability: putApplicantSchema.shape.availability,
  city: putApplicantSchema.shape.city,
  countryId: putApplicantSchema.shape.countryId,
  currentCompany: putApplicantSchema.shape.currentCompany,
  currentJob: putApplicantSchema.shape.currentJob,
  email: putApplicantSchema.shape.email,
  expectedContractType: putApplicantSchema.shape.expectedContractType,
  expectedSalary: putApplicantSchema.shape.expectedSalary,
  expectedSalaryCurrency: putApplicantSchema.shape.expectedSalaryCurrency,
  expectedSalaryPeriod: putApplicantSchema.shape.expectedSalaryPeriod,
  firstname: putApplicantSchema.shape.firstname,
  lastname: putApplicantSchema.shape.lastname,
  linkedinUrl: putApplicantSchema.shape.linkedinUrl,
  nationalityId: putApplicantSchema.shape.nationalityId,
  phone: putApplicantSchema.shape.phone,
  source: putApplicantSchema.shape.source,
  typeOfSource: putApplicantSchema.shape.typeOfSource,
  yearOfExperience: putApplicantSchema.shape.yearOfExperience,
}
