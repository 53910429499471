import { get } from '@/services/get'
import { Location } from '@/types/location/location'

/**
 * GET /locations/search
 */
export function getLocationsSearch(query: string) {
  return get<Location[]>('/locations/search', {
    params: {
      q: query,
    },
  })
}
