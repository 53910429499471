import { useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import axios, { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { applicationsApplicantInfoQuery } from '@/hooks/use-applications-queries'
import { useHelpers } from '@/hooks/use-helpers'
import { useUpdateCache } from '@/hooks/useUpdateCache'
import { applicationAssignSchema, ApplicationAssignValues } from '@/modules/application/domain/schemas/assign.schema'
import { useApplicationAssign } from '@/modules/application/presentation/hooks/useApplications'
import { userQueryOptions } from '@/modules/user/presentation/hooks/useUsers'

export const useJobAssignModal = ({
  applicationId,
  defaultValues,
  onClose,
}: {
  defaultValues: Partial<ApplicationAssignValues>
  applicationId?: string
  onClose: () => void
}) => {
  const { isOpen, setIsOpen } = useHelpers()
  const form = useForm<ApplicationAssignValues>({
    defaultValues: {
      typeOfSource: 'INTERNAL_FORWARD',
      ...defaultValues,
    },
    resolver: zodResolver(applicationAssignSchema),
  })
  const { isPending, mutate: applicationAssign } = useApplicationAssign()
  const { invalidateQueries } = useUpdateCache()
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertTitle, setAlertTitle] = useState('Duplicate applicant')

  const onSubmit = form.handleSubmit((data) => {
    applicationAssign(
      { ...data, isForced: isAlertOpen },
      {
        onError(error) {
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 409) {
              const message = (error as AxiosError<{ message: string }>).response?.data?.message
              if (message) {
                setAlertTitle(message)
              }
              setIsAlertOpen(true)
            }
          }
        },
        async onSuccess() {
          onClose()
          toast.success('Applicant assigned successfully.')
          setIsAlertOpen(false)
          if (applicationId) {
            await invalidateQueries(applicationsApplicantInfoQuery(applicationId).queryKey)
            return
          }
          await invalidateQueries(userQueryOptions(data.applicantId).queryKey)
        },
      },
    )
  })

  return {
    alertTitle,
    form,
    isAlertOpen,
    isOpen,
    isPending,
    onSubmit,
    setIsAlertOpen: () => setIsAlertOpen((val) => !val),
    setIsOpen,
  }
}
