import { ReactNode } from 'react'

import { FolderOpenIcon } from 'lucide-react'

import { EmptyState } from '@/components/empty-state'
import { FilePreview } from '@/components/file-preview'
import { MIME_TYPE } from '@/shared/constants/mime.constant'
import { getFileNameParts } from '@/utils/file'

export const ApplicantResume = ({
  emptyStateAction,
  emptyStateSubtitle,
  emptyStateTitle = 'No resume',
  url,
}: {
  emptyStateAction?: ReactNode
  emptyStateSubtitle?: string
  emptyStateTitle?: string
  url?: string
}) => {
  if (!url) {
    return (
      <EmptyState icon={FolderOpenIcon} subtitle={emptyStateSubtitle} title={emptyStateTitle || 'No resume'}>
        {emptyStateAction}
      </EmptyState>
    )
  }

  const { extension, name } = getFileNameParts(url)

  return (
    <div className="relative h-[calc(100vh-theme(spacing.56))] w-full outline outline-muted">
      <FilePreview mimeType={MIME_TYPE[extension]} name={name} url={url} />
    </div>
  )
}
