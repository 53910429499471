export const EMAIL_TEMPLATE_TAGS = [
  'APPLICANT_FIRSTNAME',
  'APPLICANT_LASTNAME',
  'APPLICATION_ID',
  'ATS_LINK',
  'CALENDLY_LINK',
  'INTERVIEW_DATE',
  'INTERVIEW_LOCATION',
  'INTERVIEW_TIME',
  'INTERVIEW_TIMEZONE',
  'INTERVIEW_TYPE',
  'JOB_ID',
  'JOB_PUBLIC_URL',
  'JOB_TITLE',
  'LEAD_RECRUITER_EMAIL',
  'LEAD_RECRUITER_FIRSTNAME',
  'LEAD_RECRUITER_LASTNAME',
  'ORGANIZATION_NAME',
  'RECRUITER_EMAIL',
  'RECRUITER_FIRSTNAME',
  'RECRUITER_LASTNAME',
  'RECRUITERS',
]
