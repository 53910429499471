import axios from 'axios'

import { ResetPasswordValues } from '@/schemas/auth'

export async function createPassword({ confirmation, email, password, token }: ResetPasswordValues) {
  try {
    const response = await axios.post<{ accessToken: string }>(`${import.meta.env.VITE_API_URL}/auth/create-password`, {
      confirmation,
      email,
      password,
      token,
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
