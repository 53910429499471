import { UserSearch, User, UserPersonalData } from '@/modules/user/domain/entities/user.entity'
import { IUserRepository } from '@/modules/user/domain/repositories/user.repository'
import { CheckEmailDto, UserCheckEmailDto } from '@/modules/user/domain/schemas/check-email'
import { UpdatePersonalDataValues } from '@/modules/user/domain/schemas/update-personal-data.schema'
import { Id } from '@/shared/domain/entities/common.entity'

import { CreateUserApplicationDto } from '../domain/schemas/create-user-application.schema.ts'
import { UploadResumeDto } from '../domain/schemas/upload-resume.schema.ts'

export class UserUseCase {
  constructor(private userRepository: IUserRepository) {}

  async search(query: string): Promise<UserSearch[]> {
    return this.userRepository.search(query)
  }

  async get(id: string): Promise<User> {
    return this.userRepository.get(id)
  }

  async getPersonalData(id: string): Promise<UserPersonalData> {
    return this.userRepository.getPersonalData(id)
  }

  async updatePersonalData(id: string, data: UpdatePersonalDataValues): Promise<Id> {
    return this.userRepository.updatePersonalData(id, data)
  }

  async checkEmail(data: CheckEmailDto): Promise<UserCheckEmailDto> {
    return this.userRepository.checkEmail(data)
  }

  async createApplication(data: CreateUserApplicationDto): Promise<Id> {
    return this.userRepository.createApplication(data)
  }

  async uploadResume(data: FormData): Promise<UploadResumeDto> {
    return this.userRepository.uploadResume(data)
  }
}
