import apiClient from '@/lib/apiClient'
import { RefusalReason } from '@/types/refusalReason.types'

import { API_ENDPOINTS } from '.'

/*
 ** getAll : GET '/refusal-reasons'
 */

export const refusalReasonService = {
  getAll: async (): Promise<RefusalReason[]> => {
    const response = await apiClient.get<RefusalReason[]>(API_ENDPOINTS.REFUSAL_REASONS)
    return response.data
  },
}
