import { queryOptions, useQuery } from '@tanstack/react-query'

import { aventaAdminService } from '@/services/aventaAdminService'

const aventaAdminEmailTemplatesQuery = queryOptions({
  queryFn: aventaAdminService.getEmailTemplates,
  queryKey: ['aventaAdminEmailTemplates'],
})

const useAventaAdminEmailTemplates = () => useQuery(aventaAdminEmailTemplatesQuery)

const aventaAdminEmailTemplateQuery = (id?: string) =>
  queryOptions({
    enabled: !!id,
    queryFn: () => aventaAdminService.getEmailTemplateById(id),
    queryKey: ['aventaAdminEmailTemplate', id],
  })

const useAventaAdminEmailTemplate = (id?: string) => useQuery(aventaAdminEmailTemplateQuery(id))

export {
  aventaAdminEmailTemplatesQuery,
  useAventaAdminEmailTemplates,
  aventaAdminEmailTemplateQuery,
  useAventaAdminEmailTemplate,
}
