import { get } from '@/services/get'
import { JobBoard } from '@/types/job/job-board'
import { JobBoardAutomations } from '@/types/job/job-board-automations'

/**
 * URL : /jobs/:jobId/board
 */
export function getJobsBoard(jobId: string) {
  return get<JobBoard>(`/jobs/${jobId}/board`)
}

/**
 * URL : /jobs/:jobId/board/automations
 */
export function getJobsBoardAutomations(jobId: string) {
  return get<JobBoardAutomations>(`/jobs/${jobId}/board/automations`)
}
