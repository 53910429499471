import { queryOptions, useQuery } from '@tanstack/react-query'

import { ApiGateway } from '@/modules/api/api.gateway'
import { JobRepository } from '@/modules/job/adapter/job.repository'
import { JobUseCase } from '@/modules/job/application/job.use-case'

const apiGateway = new ApiGateway()
const jobRepository = new JobRepository(apiGateway)
const jobUseCase = new JobUseCase(jobRepository)

const searchJobsQueryOptions = (query: string) =>
  queryOptions({
    enabled: !!query,
    queryFn: () => jobUseCase.search(query),
    queryKey: ['jobs/search', query],
  })

const useSearchJobs = (query: string) => {
  return useQuery(searchJobsQueryOptions(query))
}

const jobsStagesQueryOptions = (jobId?: string) =>
  queryOptions({
    enabled: !!jobId,
    queryFn: () => jobUseCase.getStages(jobId!),
    queryKey: ['jobs/stages', jobId],
  })

const useJobsStages = (jobId?: string) => {
  return useQuery(jobsStagesQueryOptions(jobId))
}

export { useSearchJobs, useJobsStages }
