import { ReactNode } from 'react'

import { Title } from '@/components/ui/title'

interface PageHeaderProps {
  title: string
  cta?: ReactNode
  description?: ReactNode
}

export function PageHeader({ cta, description, title }: PageHeaderProps) {
  return (
    <div className="sticky top-16 z-10 flex h-20 w-full items-center gap-x-4 border-b bg-background px-8">
      <Title as="h2" level={4}>
        {title}
      </Title>
      {description}
      <div className="ml-auto">{cta}</div>
    </div>
  )
}
