import { get } from '@/services/get'
import { EmailTemplates, EmailTemplate } from '@/types/emailTemplate'

/**
 * GET /email-templates
 */
export function getEmailTemplates() {
  return get<EmailTemplates[]>('/email-templates')
}

/**
 * GET /email-templates/:emailTemplateId
 */
export function getEmailTemplate(emailTemplateId?: string) {
  return get<EmailTemplate>(`/email-templates/${emailTemplateId}`)
}
