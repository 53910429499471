import { Country } from '@/types/country/country'
import { JobLocation } from '@/types/job'

interface FullNameProps {
  firstname?: string
  lastname?: string
}

export const getFullName = <T extends FullNameProps>({ firstname, lastname }: T): string => {
  return `${firstname ?? ''} ${lastname ?? ''}`
}

export const getFullNameInitial = <T extends FullNameProps>({ firstname, lastname }: T): string => {
  const firstnameInitials = firstname
    ? firstname
        .split(' ')
        .map((f) => f[0])
        .join('')
    : ''

  const lastnameInitials = lastname
    ? lastname
        .split(' ')
        .map((l) => l[0])
        .join('')
    : ''

  return `${firstnameInitials}${lastnameInitials}`
}

interface JobNameProps {
  currentCompany?: string
  currentJob?: string
}

export const getJobName = <T extends JobNameProps>({ currentCompany, currentJob }: T): string => {
  if (!currentJob && !currentCompany) return '-'
  return `${currentJob || '-'} ${currentCompany ? `@ ${currentCompany}` : ''}`
}

interface ApplicantLocationProps {
  city?: string
  country: Country
}

export function formatApplicantLocation<T extends ApplicantLocationProps>({ city, country }: T): string {
  if (!city && !country.name) return '-'
  return `${city || ''}${city && country.name ? ', ' : ''}${country.name || ''}`
}

export function getUniqueLocations(jobLocations: JobLocation[]): string[] {
  const locations = jobLocations.map((location) => location.location.name)
  return [...new Set(locations)]
}

export function formatJobLocations(jobLocations: JobLocation[]): string {
  return getUniqueLocations(jobLocations).join(', ')
}

export function replaceCurlyBracesWithAtSymbol(text: string) {
  return text.replace(/{(.*?)}/g, (_match, variable) => `@${variable}`)
}

export function replaceAtSymbolWithCurlyBraces(text: string) {
  return text.replace(/(?<!\w)@(\w+)/g, (_match, variable) => `{${variable}}`)
}

export function convertVariablesToMentionHtml(text: string) {
  return text.replace(/({(\w+)})(?![^<>]*>[^<]*<\/a>)/g, '<span data-type="mention" data-id="$2"></span>')
}

export function convertMentionHtmlToVariables(text?: string) {
  if (!text) return ''
  return text.replace(/<span[^>]*data-type="mention"[^>]*data-id="([^"]+)"[^>]*>[^<]*<\/span>/g, '{$1}')
}

export function removeHTMLTags(text: string) {
  return text.replace(/<[^>]*>/g, '')
}
