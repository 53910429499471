// Board.tsx
import { ReactNode } from 'react'

import { DndContext, DragOverlay } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import { createPortal } from 'react-dom'

import type { JobBoardStage } from '@/types/stage/job-board'

import { BulkActions } from './bulk-actions'
import { Card } from './card'
import { Column } from './column'
import { useJobBoard } from './useJobBoard'

interface BoardProps {
  stages: JobBoardStage[]
}

export function Board({ stages }: BoardProps) {
  const {
    handleDragEnd,
    handleDragOver,
    handleDragStart,
    sensors,
    state: { activeApplication, activeColor, applications, columns, columnsIds },
  } = useJobBoard(stages)

  return (
    <DndContext onDragEnd={handleDragEnd} onDragOver={handleDragOver} onDragStart={handleDragStart} sensors={sensors}>
      <div className="flex gap-x-4 p-8">
        <SortableContext items={columnsIds}>
          {columns.map((column) => (
            <Column
              applications={applications.filter((application) => application.stageId === column.id)}
              column={column}
              key={column.id}
            />
          ))}
        </SortableContext>
      </div>
      <BulkActions />
      {createPortal(
        <DragOverlay>
          {activeApplication && <Card application={activeApplication} color={activeColor} overlay />}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  )
}

export function BoardSkeleton({ children }: { children: ReactNode }) {
  return <div className="flex h-full gap-x-4 overflow-auto p-8">{children}</div>
}
