import { ReactNode } from 'react'

import { Text } from '@/components/ui/text'
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'

interface Props {
  className?: string
  description?: string
  name: string
  children: ReactNode
}

export function StageTooltip({ children, className, description, name }: Props) {
  if (!description) return null

  return (
    <Tooltip>
      <TooltipTrigger className={className}>{children}</TooltipTrigger>
      <TooltipContent className="max-w-64">
        <Text size="xs" variant="white" weight="semibold">
          {name}
        </Text>
        <Text size="xs" variant="white" weight="light">
          {description}
        </Text>
      </TooltipContent>
    </Tooltip>
  )
}
