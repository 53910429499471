const displayBytesToMegabytes = (bytes: number, toFixed = 2): string => {
  return `${(bytes / 1024 / 1024).toFixed(toFixed)}Mo`
}

type FileNameParts = {
  name: string
  extension: string
}

const getFileNameParts = (filePath: string): FileNameParts => {
  const fileName = filePath.split('/').pop() || filePath

  const lastDotIndex = fileName.lastIndexOf('.')

  if (lastDotIndex <= 0) {
    return {
      extension: '',
      name: fileName,
    }
  }

  return {
    extension: fileName.slice(lastDotIndex + 1),
    name: fileName.slice(0, lastDotIndex),
  }
}

export { displayBytesToMegabytes, getFileNameParts }
