import { cva } from 'class-variance-authority'

export const buttonVariants = cva(
  'relative inline-flex items-center justify-center gap-x-1 whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    defaultVariants: {
      fullwidth: false,
      size: 'default',
      variant: 'default',
    },
    variants: {
      fullwidth: {
        true: 'w-full',
      },
      size: {
        default: 'h-9 px-4 py-2',
        icon: 'size-9',
        'icon-sm': 'size-6',
        lg: 'h-10 rounded-md px-4',
        sm: 'h-8 rounded-md px-3 text-xs',
      },
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive:
          'border border-destructive bg-background text-destructive hover:bg-destructive hover:text-destructive-foreground',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        'muted-foreground': 'bg-muted-foreground text-muted hover:bg-foreground hover:text-background',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
      },
    },
  },
)

export const buttonLoadingVariants = cva('absolute inset-2 inline-flex items-center justify-center', {
  defaultVariants: {
    variant: 'default',
  },
  variants: {
    variant: {
      default: 'bg-primary',
      destructive: 'bg-background',
      ghost: 'bg-accent',
      link: 'bg-transparent',
      'muted-foreground': 'bg-muted-foreground',
      outline: 'bg-background',
      secondary: 'bg-secondary',
    },
  },
})
