import { put } from '@/services/put'
import { Job } from '@/types/job'
import { BulkApplicationsBody } from '@/types/job/bulk-applications'

/**
 * URL : /jobs/:jobId/bulk
 */
export function updateJobBulkApplications(jobId: string, body: BulkApplicationsBody) {
  return put<BulkApplicationsBody, Job[]>(`/jobs/${jobId}/bulk`, body)
}
