import { useMutation } from '@tanstack/react-query'

import { updateTasksApplication } from '@/api/tasks'
import { applicationsActivitiesQuery, applicationsLastActivitiesQuery } from '@/hooks/use-applications-queries'
import { UpdateTasksApplicationBody } from '@/types/tasks/application'

/**
 * PUT /tasks/{taskId}/application
 */
export const useUpdateTasksApplication = (taskId: string, applicationId: string) => {
  return useMutation({
    meta: {
      invalidates: [
        applicationsLastActivitiesQuery(applicationId).queryKey,
        applicationsActivitiesQuery(applicationId).queryKey,
      ],
    },
    mutationFn: (body: UpdateTasksApplicationBody) => updateTasksApplication(taskId, body),
  })
}
