import { ActionMeta, components, GroupBase, OptionProps, SingleValue } from 'react-select'

import { Select } from '@/components/select'
import { isDroppable } from '@/features/jobs/$jobId/utils'
import { useJobsStages } from '@/modules/job/presentation/hooks/useJobs'
import { Stage } from '@/shared/domain/entities/stage.entity'

export const JobStageSelect = ({
  jobId,
  onChange,
  placeholder = 'Select a job stage...',
}: {
  jobId?: string
  placeholder?: string
  onChange: (
    newValue: SingleValue<
      Stage & {
        isDisabled: boolean
      }
    >,
    actionMeta: ActionMeta<
      Stage & {
        isDisabled: boolean
      }
    >,
  ) => void
}) => {
  const { data, isLoading } = useJobsStages(jobId)

  const options =
    data?.stages.map(({ order, ...rest }) => ({
      isDisabled: !isDroppable(order),
      order,
      ...rest,
    })) || []

  return (
    <Select
      className="w-full"
      components={{
        Option: (props) => <OptionComponent {...props} />,
      }}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isDisabled={!jobId}
      isLoading={isLoading}
      menuPosition="absolute"
      onChange={onChange}
      options={options}
      placeholder={placeholder}
    />
  )
}

const OptionComponent = <
  Option extends Stage & {
    isDisabled: boolean
  },
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: OptionProps<Option, IsMulti, Group>,
) => {
  const { data } = props
  return (
    <components.Option {...props}>
      <div className="flex items-center gap-x-2">
        <i className="size-3 shrink-0 rounded-full" style={{ backgroundColor: data.color }} />
        {data.name}
      </div>
    </components.Option>
  )
}
