import { Pencil } from 'lucide-react'

import { useRefusalReasonModal } from '@/components/shared/RefusalReasonModal/RefusalReasonModalProvider'
import { Text } from '@/components/ui/text'
import { JobBoardApplication } from '@/types/application/job-board'

import { CountDown } from './count-down'

interface UpdateActionProps {
  application: JobBoardApplication
}

export function UpdateAction({ application }: UpdateActionProps) {
  const { openModal } = useRefusalReasonModal()
  const {
    customEmailToSendId,
    emailSendingDelay,
    emailTemplate,
    endEditableAt,
    id: applicationId,
    isEditable,
    refusalReason,
  } = application

  const onUpdate = () => {
    openModal({
      applicationId,
      customEmailToSendId,
      emailSendingDelay: emailSendingDelay,
      emailTemplateId: emailTemplate?.id,
      refusalReasonId: refusalReason.id,
    })
  }

  return isEditable ? (
    <>
      <button className="flex items-center justify-center gap-x-2 truncate" onClick={onUpdate}>
        <Text size="xs" truncate variant="muted">
          {refusalReason.name}
        </Text>
        <Pencil className="cursor-pointer text-muted-foreground" size={12} />
      </button>
      <div className="ml-auto shrink-0">{endEditableAt && <CountDown refusedDate={endEditableAt} />}</div>
    </>
  ) : (
    <Text size="xs" truncate variant="red">
      {refusalReason.name}
    </Text>
  )
}
