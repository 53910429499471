import { createFileRoute } from '@tanstack/react-router'

import { PageContent } from '@/components/page-content'
import { PageHeaderSkeleton } from '@/components/skeleton/page-header-skeleton'
import { TableSkeleton } from '@/components/ui/table'
import { jobsRecruitersQuery } from '@/queries/use-jobs-queries'

export const Route = createFileRoute('/_authenticated/jobs_/$jobId_/edit/recruiters')({
  loader: ({ context: { queryClient }, params: { jobId } }) => queryClient.ensureQueryData(jobsRecruitersQuery(jobId)),
  pendingComponent: () => (
    <>
      <PageHeaderSkeleton cta title="Recruiters" />
      <PageContent>
        <TableSkeleton />
      </PageContent>
    </>
  ),
})
