import { get } from '@/services/get'
import { ActivitiesApplication, ActivitiesLastApplication } from '@/types/application/activities'

/**
 * URL : /applications/:applicationId/activities
 */
export function getApplicationsActivities(applicationId: string) {
  return get<ActivitiesApplication[]>(`/applications/${applicationId}/activities`)
}

/**
 * URL : /applications/:applicationId/activities/last
 */
export function getApplicationsLastActivities(applicationId: string) {
  return get<ActivitiesLastApplication>(`/applications/${applicationId}/activities/last`)
}
