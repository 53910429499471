import apiClient from '@/lib/apiClient'
import { EmailTemplate, EmailTemplateExtended } from '@/types/emailTemplate.types'

import { API_ENDPOINTS } from '.'

export const emailTemplateService = {
  getById: async (id?: string): Promise<EmailTemplateExtended> => {
    const response = await apiClient.get<EmailTemplateExtended>(`${API_ENDPOINTS.EMAIL_TEMPLATES}/${id}`)
    return response.data
  },
  getByOrganization: async (organizationId: string): Promise<EmailTemplate[]> => {
    const response = await apiClient.get<EmailTemplate[]>(
      `${API_ENDPOINTS.EMAIL_TEMPLATES}/organization/${organizationId}`,
    )
    return response.data
  },
}
