import { z } from 'zod'

export const putJobBulkRefusalStrategySchema = z
  .object({
    emailSendingDelay: z.number().optional(),
    emailTemplateId: z.string().optional(),
    hasEmail: z.boolean().optional(),
    refusalReasonId: z
      .string({
        invalid_type_error: 'Refusal reason is required.',
        required_error: 'Refusal reason is required.',
      })
      .min(1, {
        message: 'Refusal reason is required.',
      }),
  })
  .refine((data) => !(data.hasEmail && data.emailSendingDelay === null), {
    message: 'Delay is required.',
    path: ['emailSendingDelay'],
  })
  .refine((data) => !(data.hasEmail && !data.emailTemplateId), {
    message: 'Email template is required.',
    path: ['emailTemplateId'],
  })

export type PutJobBulkRefusalStrategyValues = z.infer<typeof putJobBulkRefusalStrategySchema>
export const putJobBulkRefusalStrategyValidations = {
  emailSendingDelay: putJobBulkRefusalStrategySchema.innerType().innerType().shape.emailSendingDelay,
  emailTemplateId: putJobBulkRefusalStrategySchema.innerType().innerType().shape.emailTemplateId,
  hasEmail: putJobBulkRefusalStrategySchema.innerType().innerType().shape.hasEmail,
  refusalReasonId: putJobBulkRefusalStrategySchema.innerType().innerType().shape.refusalReasonId,
}

export const putJobBulkSendEmailSchema = z.object({
  body: z.string().min(1, 'Content is required.'),
  emailTemplateId: z.string().optional(),
  subject: z.string().min(1, 'Subject is required.'),
})

export type PutJobBulkSendEmailValues = z.infer<typeof putJobBulkSendEmailSchema>
export const putJobBulkSendEmailValidations = {
  body: putJobBulkSendEmailSchema.shape.body,
  emailTemplateId: putJobBulkSendEmailSchema.shape.emailTemplateId,
  subject: putJobBulkSendEmailSchema.shape.subject,
}
