import { ApplicationAssign } from '@/modules/application/domain/entities/application.entity'
import { IApplicationRepository } from '@/modules/application/domain/repositories/application.repository'
import { CreateApplicationDto } from '@/modules/application/domain/schemas/create.schema'
import { Id } from '@/shared/domain/entities/common.entity'

export class ApplicationUseCase {
  constructor(private applicationRepository: IApplicationRepository) {}

  async assign(data: ApplicationAssign): Promise<Id> {
    return this.applicationRepository.assign(data)
  }

  async create(data: CreateApplicationDto): Promise<Id> {
    return this.applicationRepository.create(data)
  }
}
