import apiClient from '@/lib/apiClient'
import { RefuseApplication, UpdateStage } from '@/schemas/application.schema'
import { IdResponse } from '@/types/common.types'

import { API_ENDPOINTS } from '.'

export const applicationService = {
  refuseApplication: async (applicationId: string, data: RefuseApplication): Promise<IdResponse> => {
    const response = await apiClient.put<IdResponse>(
      `${API_ENDPOINTS.APPLICATIONS}/${applicationId}/refuse-application`,
      data,
    )
    return response.data
  },
  updateStage: async (applicationId: string, data: UpdateStage): Promise<IdResponse> => {
    const response = await apiClient.put<IdResponse>(
      `${API_ENDPOINTS.APPLICATIONS}/${applicationId}/update-stage`,
      data,
    )
    return response.data
  },
}
