import { memo, useMemo } from 'react'

import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { CalendarIcon, CalendarXIcon, InfoIcon, LucideIcon } from 'lucide-react'
import { Control, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form'
import { Value as PhoneNumberValue } from 'react-phone-number-input'
import { ZodSchema } from 'zod'

import { cn } from '@/lib/utils'

import { Button } from './ui/button'
import { Calendar } from './ui/calendar'
import { Checkbox } from './ui/checkbox'
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from './ui/form'
import { Input } from './ui/input'
import { PhoneNumberInput } from './ui/phone-number'
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover'
import { Switch } from './ui/switch'
import { Textarea } from './ui/textarea'
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip'

export enum FormFieldType {
  CHECKBOX = 'checkbox',
  DATE_PICKER = 'datePicker',
  INPUT = 'input',
  NUMBER_INPUT = 'numberInput',
  PHONE_INPUT = 'phoneInput',
  SELECT = 'select',
  SKELETON = 'skeleton',
  SWITCH = 'switch',
  TEXTAREA = 'textarea',
}

interface CustomFormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  children?: React.ReactNode
  control: Control<TFieldValues>
  dateFormat?: string
  disabled?: boolean
  fieldType: FormFieldType
  label?: React.ReactNode
  name: TName
  description?: React.ReactNode
  information?: React.ReactNode
  placeholder?: string
  showTimeSelect?: boolean
  tooltipContent?: string
  descriptionTooltipContent?: string
  type?: 'text' | 'email' | 'password' | 'hidden'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation?: ZodSchema<any>
  renderSkeleton?: (field: ControllerRenderProps<TFieldValues, TName>) => React.ReactNode
}

function RenderFieldComponent<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  field,
  props,
}: {
  field: ControllerRenderProps<TFieldValues, TName>
  props: CustomFormFieldProps<TFieldValues, TName>
}) {
  const { disabled, fieldType, placeholder, renderSkeleton, type = 'text' } = props

  switch (fieldType) {
    case FormFieldType.DATE_PICKER:
      return (
        <Popover>
          <FormControl>
            <div className="flex gap-x-1">
              <PopoverTrigger asChild>
                <Button
                  className={cn('pl-3 text-left font-normal flex-1', !field.value && 'text-muted-foreground')}
                  variant="outline"
                >
                  <>
                    {field.value ? (
                      format(field.value, 'P', { locale: fr })
                    ) : (
                      <span>{placeholder ?? 'Pick a date'}</span>
                    )}
                    <CalendarIcon className="ml-auto size-4 opacity-50" />
                  </>
                </Button>
              </PopoverTrigger>
              <Button
                className="shrink-0"
                disabled={!field.value}
                onClick={() => field.onChange(null)}
                size="icon"
                variant="secondary"
              >
                <CalendarXIcon size={16} />
              </Button>
            </div>
          </FormControl>
          <PopoverContent align="start" className="w-auto p-0">
            <Calendar
              autoFocus
              defaultMonth={field.value}
              disabled={disabled}
              mode="single"
              onSelect={field.onChange}
              selected={field.value}
            />
          </PopoverContent>
        </Popover>
      )
    case FormFieldType.INPUT:
      return (
        <FormControl>
          <Input type={type} {...field} disabled={disabled} placeholder={placeholder} />
        </FormControl>
      )
    case FormFieldType.NUMBER_INPUT:
      return (
        <FormControl>
          <Input
            {...field}
            disabled={disabled}
            min={0}
            onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value, 10) : 0)}
            placeholder={placeholder}
            type="number"
          />
        </FormControl>
      )
    case FormFieldType.PHONE_INPUT:
      return (
        <FormControl>
          <PhoneNumberInput disabled={disabled} onChange={field.onChange} value={field.value as PhoneNumberValue} />
        </FormControl>
      )
    case FormFieldType.SKELETON:
      return renderSkeleton ? renderSkeleton(field) : null
    case FormFieldType.SWITCH:
      return (
        <FormControl>
          <Switch checked={field.value} disabled={disabled} onCheckedChange={field.onChange} />
        </FormControl>
      )
    case FormFieldType.TEXTAREA:
      return (
        <FormControl>
          <Textarea disabled={disabled} {...field} className="min-h-32" placeholder={placeholder} />
        </FormControl>
      )
    case FormFieldType.CHECKBOX:
      return (
        <FormControl>
          <Checkbox checked={field.value} disabled={disabled} onCheckedChange={field.onChange} />
        </FormControl>
      )
    default:
      return null
  }
}

const RenderField = memo(RenderFieldComponent) as typeof RenderFieldComponent

const RenderLabel: React.FC<{
  label: React.ReactNode
  tooltipContent?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation?: ZodSchema<any>
}> = memo(({ label, tooltipContent, validation }) => (
  <FormLabel className="inline-flex items-center gap-x-1 whitespace-pre-line" validation={validation}>
    {label}
    {tooltipContent && (
      <Tooltip>
        <TooltipTrigger type="button">
          <InfoIcon className="fill-primary text-white" size={20} />
        </TooltipTrigger>
        <TooltipContent className="max-w-96">{tooltipContent}</TooltipContent>
      </Tooltip>
    )}
  </FormLabel>
))

RenderLabel.displayName = 'RenderLabel'

const RenderDescription: React.FC<{
  description: React.ReactNode
  descriptionTooltipContent?: string
}> = memo(({ description, descriptionTooltipContent }) => (
  <div className="inline-flex items-center gap-x-1 whitespace-pre-line">
    <FormDescription>{description}</FormDescription>
    {descriptionTooltipContent && (
      <Tooltip>
        <TooltipTrigger type="button">
          <InfoIcon className="fill-primary text-white" size={20} />
        </TooltipTrigger>
        <TooltipContent className="max-w-96">{descriptionTooltipContent}</TooltipContent>
      </Tooltip>
    )}
  </div>
))

RenderDescription.displayName = 'RenderDescription'

const RenderInformation: React.FC<{
  information: React.ReactNode
  icon?: LucideIcon
}> = memo(({ icon: Icon = InfoIcon, information }) => (
  <div className="inline-flex items-center gap-x-1 whitespace-pre-line">
    <FormDescription className="flex items-center gap-x-1">
      {Icon && <Icon className="shrink-0" size={16} />}
      {information}
    </FormDescription>
  </div>
))

RenderInformation.displayName = 'RenderInformation'

export function CustomFormField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: CustomFormFieldProps<TFieldValues, TName>) {
  const {
    control,
    description,
    descriptionTooltipContent,
    fieldType,
    information,
    label,
    name,
    tooltipContent,
    validation,
  } = props

  const RenderContent = useMemo(() => {
    const Component = ({ field }: { field: ControllerRenderProps<TFieldValues, TName> }) => (
      <FormItem>
        {fieldType === FormFieldType.CHECKBOX ? (
          <>
            <div className="flex items-center gap-x-2">
              <RenderField<TFieldValues, TName> field={field} props={props} />
              {label && <RenderLabel label={label} tooltipContent={tooltipContent} validation={validation} />}
            </div>
            {description && (
              <RenderDescription description={description} descriptionTooltipContent={descriptionTooltipContent} />
            )}
            {information && <RenderInformation information={information} />}
          </>
        ) : (
          <>
            {label && <RenderLabel label={label} tooltipContent={tooltipContent} validation={validation} />}
            {description && (
              <RenderDescription description={description} descriptionTooltipContent={descriptionTooltipContent} />
            )}
            <RenderField<TFieldValues, TName> field={field} props={props} />
            {information && <RenderInformation information={information} />}
          </>
        )}
        <FormMessage />
      </FormItem>
    )
    Component.displayName = 'RenderContent'
    return Component
  }, [fieldType, props, label, validation, tooltipContent, description, descriptionTooltipContent, information])

  return <FormField control={control} name={name} render={RenderContent} />
}
