import { Link } from '@tanstack/react-router'
import { DockIcon, MapPinIcon } from 'lucide-react'

import { EmptyState } from '@/components/empty-state'
import { JobStatusBadge } from '@/components/job-status-badge'
import { RecruitersAvatar } from '@/components/recruiters-avatar'
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { Text } from '@/components/ui/text'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { HistoryOfApplication } from '@/types/application/applicant-info'
import { getFormattedDate } from '@/utils/date'
import { getUniqueLocations } from '@/utils/string'

export const ApplicantApplicationsHistory = ({ applications }: { applications: HistoryOfApplication[] }) => {
  if (applications.length === 0) {
    return <EmptyState icon={DockIcon} title="No applications." />
  }

  return (
    <Table>
      <TableBody>
        {applications.map((application) => (
          <TableRow key={application.applicationId}>
            <TableCell className="w-5">
              <JobStatusBadge status={application.jobStatus} />
            </TableCell>
            <TableCell>
              <Link
                className="underline hover:text-primary"
                params={{ applicationId: application.applicationId, jobId: application.jobId }}
                search={{ view: 'application' }}
                target="_blank"
                title="Open in new tab"
                to="/jobs/$jobId/applications/$applicationId"
              >
                {application.jobTitle}
              </Link>
            </TableCell>
            <TableCell>{application.organization.name}</TableCell>
            <TableCell>
              <Tooltip>
                <TooltipTrigger>
                  <MapPinIcon className="text-muted-foreground" size={16} />
                </TooltipTrigger>
                <TooltipContent>
                  {getUniqueLocations(application.jobLocations).map((location) => (
                    <p key={`${location}-${application.jobId}`}>{location}</p>
                  ))}
                </TooltipContent>
              </Tooltip>
            </TableCell>
            <TableCell>{application.stageName}</TableCell>
            <TableCell>
              <RecruitersAvatar recruiters={application.recruiters} size="sm" />
            </TableCell>
            <TableCell className="text-right">
              <Text size="sm" variant="muted" weight="light">
                {getFormattedDate(application.stageUpdatedAt)}
              </Text>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
