export function isBulkableOrDraggable(stageName: string) {
  return !['Refused', 'Success'].includes(stageName)
}

export function isDroppable(stageOrder: number) {
  return stageOrder !== -10
}

export function getDisabledStages(stageOrder: number) {
  return stageOrder === 100 || stageOrder === 110
}
