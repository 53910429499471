import { ReactNode, useMemo } from 'react'

import { SortableContext, useSortable } from '@dnd-kit/sortable'
import { InfoIcon } from 'lucide-react'

import { Checkbox } from '@/components/ui/checkbox'
import { Skeleton } from '@/components/ui/skeleton'
import { Text } from '@/components/ui/text'
import { useBulkActions } from '@/hooks/use-bulk-actions'
import { JobBoardApplicationWithStageId } from '@/types/application/job-board'
import { JobBoardColumn } from '@/types/stage/job-board'

import { Card } from './card'
import { StageTooltip } from './components/stage-tootltip'
import { SortableCard } from './sortable-card'
import { isBulkableOrDraggable } from './utils'

interface ColumnProps {
  column: JobBoardColumn
  applications: JobBoardApplicationWithStageId[]
}

export function Column({ applications, column }: ColumnProps) {
  const { isOpen, selected, setSelected } = useBulkActions()
  const { attributes, listeners, setNodeRef } = useSortable({
    data: {
      column,
      type: 'column',
    },
    disabled: true,
    id: column.id,
  })
  const applicationsIds = useMemo(() => applications.map((application) => application.id), [applications])
  const isAllChecked = useMemo(() => {
    if (!isBulkableOrDraggable(column.name) || !applications.length) return false
    return applicationsIds.every((id) => selected.includes(id))
      ? true
      : applicationsIds.some((id) => selected.includes(id))
        ? 'indeterminate'
        : false
  }, [applications.length, applicationsIds, column.name, selected])

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className="flex h-full w-96 shrink-0 cursor-default flex-col gap-y-2"
    >
      <div className="flex items-center justify-between">
        <div className="flex w-full items-center gap-x-2">
          <span className="block size-3 shrink-0 rounded-full" style={{ backgroundColor: column.color }} />
          <Text weight="semibold">
            {column.name} ({column.count})
          </Text>

          <StageTooltip className="ml-auto" description={column.description} name={column.name}>
            <InfoIcon className="text-muted-foreground" size={16} />
          </StageTooltip>
        </div>
        {isBulkableOrDraggable(column.name) && isOpen && (
          <div className="flex shrink-0 items-center gap-x-1 pr-7">
            <Text size="sm" variant="muted" weight="light">
              {isAllChecked === true ? 'Unselect all' : 'Select all'}
            </Text>
            <Checkbox
              checked={isAllChecked}
              disabled={applications.length === 0}
              id={column.id}
              onCheckedChange={() => {
                if (isAllChecked === 'indeterminate' || isAllChecked === false) {
                  setSelected([...selected, ...applicationsIds])
                } else {
                  setSelected(selected.filter((id) => !applicationsIds.includes(id)))
                }
              }}
            />
          </div>
        )}
      </div>
      <div className="flex flex-1 flex-col gap-y-4 overflow-y-auto overflow-x-hidden rounded bg-muted-foreground/5 p-4">
        <SortableContext items={applicationsIds}>
          {applications.map((application) => {
            if (isBulkableOrDraggable(application.stageName)) {
              return <SortableCard application={application} color={column.color} key={application.id} />
            }
            return <Card application={application} color={column.color} key={application.id} />
          })}
        </SortableContext>
      </div>
    </div>
  )
}
export function ColumnSkeleton({ children }: { children: ReactNode }) {
  return (
    <div className="flex h-full w-96 shrink-0 flex-col space-y-2">
      <div className="flex items-center gap-x-2">
        <Skeleton className="size-3 rounded-full" />
        <Skeleton className="h-6 w-20" />
      </div>
      <div className="flex flex-1 flex-col gap-y-4 rounded bg-muted-foreground/5 p-4">{children}</div>
    </div>
  )
}
