import { useQuery } from '@tanstack/react-query'
import { MenuPosition } from 'react-select'

import { Select } from '@/components/select'
import { nationalitiesQuery } from '@/queries/use-nationalities-queries'

interface NationalitySelectProps {
  value?: string | null
  onChange: (value: string) => void
  placeholder?: string
  disabled?: boolean
  menuPosition?: MenuPosition
}

export function NationalitySelect({
  disabled,
  menuPosition,
  onChange,
  placeholder = 'Select nationality...',
  value,
}: NationalitySelectProps) {
  const { data: nationalities, isLoading } = useQuery(nationalitiesQuery)

  return (
    <Select
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isDisabled={disabled}
      isLoading={isLoading}
      menuPosition={menuPosition}
      onChange={(value) => value && onChange(value.id)}
      options={nationalities}
      placeholder={placeholder}
      value={nationalities?.find(({ id }) => value === id)}
    />
  )
}
