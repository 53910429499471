import { ReactNode } from '@tanstack/react-router'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Form } from '@/components/ui/form'
import { JobSearchSelect } from '@/shared/presentation/job/JobSearchSelect'
import { JobStageSelect } from '@/shared/presentation/job/JobStageSelect'

import { useJobAssignModal } from './useJobAssignModal'

export const JobAssignModal = ({
  applicantId,
  applicationId,
  children,
}: {
  children: ReactNode
  applicantId: string
  applicationId?: string
}) => {
  const { alertTitle, form, isAlertOpen, isOpen, isPending, onSubmit, setIsAlertOpen, setIsOpen } = useJobAssignModal({
    applicationId,
    defaultValues: {
      applicantId,
      source: applicationId ? `application: {{${applicationId}}}` : 'APPLICANT_SEARCH',
    },
    onClose: () => setIsOpen(false),
  })
  const { control, watch } = form

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Assign this applicant</DialogTitle>
          <DialogDescription className="hidden">
            Assign this applicant to a job and stage. The applicant will be added to the job pipeline.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-4" onSubmit={onSubmit}>
            <CustomFormField
              control={control}
              fieldType={FormFieldType.SKELETON}
              label="Job title"
              name="jobId"
              renderSkeleton={(field) => (
                <JobSearchSelect
                  onChange={(data) => {
                    field.onChange(data?.id)
                  }}
                />
              )}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.SKELETON}
              label="Stage"
              name="stageId"
              renderSkeleton={(field) => (
                <JobStageSelect
                  jobId={watch('jobId')}
                  onChange={(data) => {
                    field.onChange(data?.id)
                  }}
                />
              )}
            />
            <DialogFooter>
              <DialogClose asChild>
                <Button disabled={isPending} type="button" variant="outline">
                  Cancel
                </Button>
              </DialogClose>
              <Button isLoading={isPending} type="submit">
                Assign
              </Button>
            </DialogFooter>
            <JobAssignAlert
              isPending={isPending}
              onOpenChange={setIsAlertOpen}
              onSubmit={onSubmit}
              open={isAlertOpen}
              title={alertTitle}
            />
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

const JobAssignAlert = ({
  isPending,
  onOpenChange,
  onSubmit,
  open,
  title,
}: {
  title: string
  onOpenChange: () => void
  open: boolean
  isPending: boolean
  onSubmit: () => void
}) => {
  return (
    <AlertDialog onOpenChange={onOpenChange} open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>
            Would you like to modify the information or add the applicant?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isPending}>Modify</AlertDialogCancel>
          <AlertDialogAction disabled={isPending} onClick={onSubmit}>
            Add applicant
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
