import { useMutation } from '@tanstack/react-query'

import { createEmailsToSendCustom } from '@/api/emails-to-send'
import { CreateEmailsToSendCustomBody } from '@/types/emails-to-send/custom'

/**
 * POST /emails-to-send/custom
 */
export function useCreateEmailsToSendCustom() {
  return useMutation({
    mutationFn: ({ body }: { body: CreateEmailsToSendCustomBody }) => createEmailsToSendCustom(body),
  })
}
