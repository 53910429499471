import { zodResolver } from '@hookform/resolvers/zod'
import { useParams } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { DelaySelect } from '@/components/form/selects/DelaySelect'
import { OrganizationEmailTemplatesSelect } from '@/components/form/selects/OrganizationEmailTemplatesSelect'
import { RefusalReasonsSelect } from '@/components/form/selects/RefusalReasonsSelect'
import { Button } from '@/components/ui/button'
import { Form, FormControl } from '@/components/ui/form'
import { SheetClose, SheetFooter } from '@/components/ui/sheet'
import { useBulkActions } from '@/hooks/use-bulk-actions'
import { useUpdateJobBulkApplications } from '@/queries/use-jobs-mutations'
import {
  PutJobBulkRefusalStrategyValues,
  putJobBulkRefusalStrategySchema,
  putJobBulkRefusalStrategyValidations as validations,
} from '@/schemas/jobs/jobId/board'

interface RefusalStrategyFormProps {
  onClose: () => void
}

export function RefusalStrategyForm({ onClose }: RefusalStrategyFormProps) {
  const { jobId } = useParams({ from: '/_authenticated/jobs_/$jobId' })
  const { clearSelected, selected, toggleOpen } = useBulkActions()
  const { mutate: updateJobBulkApplications } = useUpdateJobBulkApplications(jobId)

  const form = useForm<PutJobBulkRefusalStrategyValues>({
    defaultValues: {
      emailSendingDelay: undefined,
      emailTemplateId: undefined,
      hasEmail: false,
      refusalReasonId: '',
    },
    resolver: zodResolver(putJobBulkRefusalStrategySchema),
  })

  const { control, handleSubmit, watch } = form

  const onSubmit = (values: PutJobBulkRefusalStrategyValues) => {
    updateJobBulkApplications(
      {
        action: 'REFUSE_APPLICATION',
        applicationIds: selected,
        value: {
          emailSendingDelay: values.hasEmail ? values.emailSendingDelay : undefined,
          emailTemplateId: values.hasEmail ? values.emailTemplateId : undefined,
          refusalReasonId: values.refusalReasonId,
        },
      },
      {
        onSuccess: () => {
          toast.success(`${selected.length > 1 ? 'Applicants' : 'Applicant'} refused successfully.`)
          clearSelected()
          onClose()
          toggleOpen()
        },
      },
    )
  }

  return (
    <Form {...form}>
      <form className="mt-8 flex flex-col gap-y-4" onSubmit={handleSubmit(onSubmit)}>
        <CustomFormField
          control={control}
          fieldType={FormFieldType.SKELETON}
          label="Refusal reason"
          name="refusalReasonId"
          renderSkeleton={(field) => (
            <FormControl>
              <RefusalReasonsSelect onChange={field.onChange} value={field.value} />
            </FormControl>
          )}
          validation={validations.refusalReasonId}
        />
        <CustomFormField
          control={control}
          fieldType={FormFieldType.SWITCH}
          label="Send email"
          name="hasEmail"
          validation={validations.hasEmail}
        />

        {watch('hasEmail') && (
          <>
            <CustomFormField
              control={control}
              fieldType={FormFieldType.SKELETON}
              label="Email template"
              name="emailTemplateId"
              renderSkeleton={(field) => (
                <FormControl>
                  <OrganizationEmailTemplatesSelect onChange={field.onChange} value={field.value} />
                </FormControl>
              )}
              validation={validations.emailTemplateId}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.SKELETON}
              label="Delay"
              name="emailSendingDelay"
              renderSkeleton={(field) => (
                <FormControl>
                  <DelaySelect onChange={field.onChange} value={field.value} />
                </FormControl>
              )}
              validation={validations.emailSendingDelay}
            />
          </>
        )}

        <SheetFooter className="mt-8">
          <SheetClose asChild>
            <Button variant="outline">Cancel</Button>
          </SheetClose>
          <Button type="submit">Update</Button>
        </SheetFooter>
      </form>
    </Form>
  )
}
