import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { PageMenu } from '@/components/page-menu'
import { Skeleton } from '@/components/ui/skeleton'
import { BoardSkeleton } from '@/features/jobs/$jobId/board'
import { CardSkeleton } from '@/features/jobs/$jobId/card'
import { ColumnSkeleton } from '@/features/jobs/$jobId/column'
import { jobsBoardAutomationsQuery, jobsBoardQuery } from '@/queries/use-jobs-queries'
import { automationStepEnum } from '@/schemas/jobs/jobId/automation'

export const Route = createFileRoute('/_authenticated/jobs_/$jobId')({
  loader: async ({ context: { queryClient }, params: { jobId } }) => {
    await queryClient.ensureQueryData(jobsBoardQuery(jobId))
    await queryClient.ensureQueryData(jobsBoardAutomationsQuery(jobId))
  },
  pendingComponent: () => (
    <div className="flex h-full flex-col">
      <PageMenu>
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-x-2">
            <Skeleton className="size-4" />
            <Skeleton className="size-4" />
            <Skeleton className="h-6 w-56" />
            <Skeleton className="h-4 w-32" />
          </div>
          <Skeleton className="size-10 rounded-full" />
        </div>
      </PageMenu>
      <PageMenu className="flex w-full justify-center gap-x-2">
        <Skeleton className="size-6 rounded-full" />
        <Skeleton className="size-6 rounded-full" />
        <Skeleton className="size-6 rounded-full" />
      </PageMenu>
      <div className="flex-1">
        <BoardSkeleton>
          <ColumnSkeleton>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </ColumnSkeleton>
          <ColumnSkeleton>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </ColumnSkeleton>
          <ColumnSkeleton>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </ColumnSkeleton>
        </BoardSkeleton>
      </div>
    </div>
  ),
  validateSearch: z.object({
    automation: z.boolean().optional(),
    automationId: z.string().optional(),
    stageId: z.string().optional(),
    step: automationStepEnum.optional(),
  }),
})
