import { zodResolver } from '@hookform/resolvers/zod'
import { useParams } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { ConfigSelect } from '@/components/form/config-select'
import { CountrySelect } from '@/components/form/country-select'
import { NationalitySelect } from '@/components/form/nationality-select'
import { Button } from '@/components/ui/button'
import { Form, FormControl } from '@/components/ui/form'
import { Title } from '@/components/ui/title'
import { useUpdateApplicationsApplicant } from '@/hooks/use-applications-queries'
import { PutApplicantValues, putApplicantSchema, validations } from '@/schemas/applications/applicant/update'
import { ApplicantInfo } from '@/types/application/applicant-info'

interface ApplicantProps {
  data: ApplicantInfo['applicant'] & {
    applicationSource: ApplicantInfo['source']
    applicationTypeOfSource: ApplicantInfo['typeOfSource']
  }
}

export function Applicant({ data }: ApplicantProps) {
  const { applicationId } = useParams({ from: '/_authenticated/jobs_/$jobId/applications/$applicationId' })
  const { mutate: updateApplicationsApplicant } = useUpdateApplicationsApplicant(applicationId)
  const form = useForm<PutApplicantValues>({
    defaultValues: {
      applicationSource: data.applicationSource,
      applicationTypeOfSource: data.applicationTypeOfSource,
      availability: data.availability ? new Date(data.availability) : null,
      city: data.city ?? '',
      countryId: data.country?.id,
      currentCompany: data.currentCompany ?? '',
      currentJob: data.currentJob ?? '',
      email: data.email ?? '',
      expectedContractType: data.expectedContractType,
      expectedSalary: data.expectedSalary ?? undefined,
      expectedSalaryCurrency: data.expectedSalaryCurrency,
      expectedSalaryPeriod: data.expectedSalaryPeriod,
      firstname: data.firstname ?? '',
      lastname: data.lastname ?? '',
      linkedinUrl: data.linkedinUrl ?? '',
      nationalityId: data.nationality?.id,
      phone: data.phone ?? '',
      source: data.source,
      typeOfSource: data.typeOfSource,
      yearOfExperience: data.yearOfExperience ?? undefined,
    },
    resolver: zodResolver(putApplicantSchema),
  })

  const { control, handleSubmit } = form

  const onSubmit = (values: PutApplicantValues) => {
    updateApplicationsApplicant(
      { body: values },
      {
        onSuccess: () => {
          toast.success('Personal data updated successfully.')
        },
      },
    )
  }

  return (
    <Form {...form}>
      <form className="space-y-12" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-4">
          <Title as="h4" level={6}>
            Personal information
          </Title>
          <div className="grid grid-cols-3 gap-4">
            <CustomFormField
              control={control}
              fieldType={FormFieldType.INPUT}
              label="First name"
              name="firstname"
              placeholder="Aurélien"
              validation={validations.firstname}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.INPUT}
              label="Last name"
              name="lastname"
              placeholder="Zuccarini"
              validation={validations.lastname}
            />
            <CustomFormField
              control={form.control}
              fieldType={FormFieldType.SKELETON}
              label="Nationality"
              name="nationalityId"
              renderSkeleton={(field) => (
                <FormControl>
                  <NationalitySelect onChange={field.onChange} value={field.value} />
                </FormControl>
              )}
              validation={validations.nationalityId}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.INPUT}
              label="Email"
              name="email"
              placeholder="azuccarini@aventa.fr"
              type="email"
              validation={validations.email}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.PHONE_INPUT}
              label="Phone"
              name="phone"
              validation={validations.phone}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.INPUT}
              label="LinkedIn url"
              name="linkedinUrl"
              placeholder="https://www.linkedin.com/"
              validation={validations.linkedinUrl}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.INPUT}
              label="City"
              name="city"
              placeholder="City"
              validation={validations.city}
            />
            <CustomFormField
              control={form.control}
              fieldType={FormFieldType.SKELETON}
              label="Country"
              name="countryId"
              renderSkeleton={(field) => (
                <FormControl>
                  <CountrySelect onChange={field.onChange} value={field.value} />
                </FormControl>
              )}
              validation={validations.countryId}
            />
          </div>
        </div>
        <div className="space-y-4">
          <Title as="h4" level={6}>
            Job information
          </Title>
          <div className="grid grid-cols-3 gap-4">
            <CustomFormField
              control={control}
              fieldType={FormFieldType.INPUT}
              label="Current job"
              name="currentJob"
              placeholder="Marine Engineer"
              validation={validations.currentJob}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.INPUT}
              label="Current company"
              name="currentCompany"
              placeholder="Aventa"
              validation={validations.currentCompany}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.NUMBER_INPUT}
              label="Experience (in years)"
              name="yearOfExperience"
              placeholder="Years of Experience"
              validation={validations.yearOfExperience}
            />
          </div>
        </div>
        <div className="space-y-4">
          <Title as="h4" level={6}>
            Job preferences
          </Title>
          <div className="grid grid-cols-3 gap-4">
            <CustomFormField
              control={form.control}
              fieldType={FormFieldType.SKELETON}
              label="Expected contract type"
              name="expectedContractType"
              renderSkeleton={(field) => (
                <FormControl>
                  <ConfigSelect onChange={field.onChange} type="contractTypes" value={field.value as string} />
                </FormControl>
              )}
              validation={validations.expectedContractType}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.DATE_PICKER}
              label="Availability"
              name="availability"
            />
          </div>
          <div className="grid grid-cols-3 gap-4">
            <CustomFormField
              control={control}
              fieldType={FormFieldType.NUMBER_INPUT}
              label="Expected salary"
              name="expectedSalary"
              placeholder="Expected salary"
              validation={validations.expectedSalary}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.SKELETON}
              label="Salary currency"
              name="expectedSalaryCurrency"
              renderSkeleton={(field) => (
                <ConfigSelect onChange={field.onChange} type="salaryCurrencies" value={field.value} />
              )}
              validation={validations.expectedSalaryCurrency}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.SKELETON}
              label="Salary period"
              name="expectedSalaryPeriod"
              renderSkeleton={(field) => (
                <ConfigSelect onChange={field.onChange} type="salaryPeriods" value={field.value} />
              )}
              validation={validations.expectedSalaryPeriod}
            />
          </div>
        </div>
        <div className="space-y-4">
          <Title as="h4" level={6}>
            Sources
          </Title>
          <div className="grid grid-cols-3 gap-4">
            <CustomFormField
              control={control}
              disabled
              fieldType={FormFieldType.SKELETON}
              label="Applicant type of source"
              name="typeOfSource"
              renderSkeleton={(field) => (
                <FormControl>
                  <ConfigSelect disabled onChange={field.onChange} type="typeOfSources" value={field.value} />
                </FormControl>
              )}
              validation={validations.typeOfSource}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.SKELETON}
              label="Applicant source"
              name="source"
              renderSkeleton={(field) => (
                <FormControl>
                  <ConfigSelect disabled onChange={field.onChange} type="sources" value={field.value} />
                </FormControl>
              )}
              validation={validations.source}
            />
          </div>
          <div className="grid grid-cols-3 gap-4">
            <CustomFormField
              control={control}
              disabled
              fieldType={FormFieldType.SKELETON}
              label="Application type of source"
              name="applicationTypeOfSource"
              renderSkeleton={(field) => (
                <FormControl>
                  <ConfigSelect onChange={field.onChange} type="typeOfSources" value={field.value} />
                </FormControl>
              )}
              validation={validations.applicationTypeOfSource}
            />
            <CustomFormField
              control={control}
              fieldType={FormFieldType.SKELETON}
              label="Application source"
              name="applicationSource"
              renderSkeleton={(field) => (
                <FormControl>
                  <ConfigSelect onChange={field.onChange} type="sources" value={field.value} />
                </FormControl>
              )}
              validation={validations.applicationSource}
            />
          </div>
        </div>
        <div className="col-span-3 text-right">
          <Button type="submit">Update</Button>
        </div>
      </form>
    </Form>
  )
}
